.order-detail-top-row-container{
    text-align: left;
    padding: 25px;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 100px;
    position: relative;
    z-index: 10;
    @media (min-width: 1200px){
       padding: 25px;
       height: 30px;
       box-shadow: -2px 0px 20px 0px rgba(0,0,0,0.4);
    }
    .row-item{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .left-column{
        box-sizing: border-box;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        @media (min-width: 1200px){
            margin-bottom: 0;
        }
        svg {
            color: var(--light-primary-color);
        }
    }

    .right-column{
        @media (min-width: 1200px){
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .top-row-title{
        font-weight: bold;
        font-size: 22px;
        margin-left: 20px;
    }
}