.route-detail-steps-container{
    overflow: hidden;
    .row{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title-row{
        font-size: 22px;
        margin-bottom: 20px;
        svg {
            color: var(--primary-color);
        }
        .mall-address{
            font-size: 12px;
            margin-left: 38px;
            color: #ababab;
        }
    }

    .title-text{
        margin-left: 10px;
        font-size: 16px;
    }

    .tags-container{
        width: 85%;
    }

    .draggable-container {
        background-color: #f5f5f5;
    }
    .route-list-container {
        display: flex;
        width: 100%;
        align-items: flex-start;
        padding-left: 11px;

    }

    .address-item{
        .index {
            font-weight: bold;
            font-size: 16px;
            margin-top: -5px;
            margin-bottom: 10px;
            color: #fff;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
        .message{
            color:#DD0000;
        }
        padding-top: 30px;
        margin-bottom: 20px;
        &.route-details {
            padding-bottom: 30px;
            .line-span{
                top: 45px;
            }
            .circle-span{
                top: 30px;
            }
        }
        padding-left: 22px;
        font-size: 12px;
        position: relative;
        width: 100%;

        .line-span{
            content: '';
            width: 5px;
            height: 100%;
            position: absolute;
            top: 45px;
            left: 0px;
        }

        .circle-span{
            content: '';
            width: 15px;
            height: 15px;
            position: absolute;
            top: 30px;
            left: -5px;
            border-radius: 50%; 
        }
        .store-name{
            font-size: 16px;
            font-weight: bold;
        }
        .reference{
            margin-top: 10px;
        }
        .payment-name{
            margin-top: 5px;
        }
        .totals{
            margin-top: 4px;
        }

        .address-value-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        .rate-return-checkbox-container {
            margin: 15px 0;
        }
        .order-canceled-container {
            display: flex;
            align-items: flex-start;
            margin: 10px 0px;
            .text{
                margin-right: 5px;
                color: var(--red);
                font-weight: bold;
            }
        }

        .route-orders-rates-container {
            .order-rates-section {
                margin-top: 10px;
                &:last-child {
                    margin-bottom: 15px;
                }
                .rate-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    .rate-section-title {
                        width: 40%;
                        font-size: 10px;
                        @media (min-width: 1600px){
                            font-size: 12px
                        }
                    }
                    .rate-value {
                        width: 30%;
                        font-size: 12px;
                        display: flex;
                        margin-left: 20px;
                        svg {
                            color: var(--light-primary-color);
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .order-actions-container{
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-top: -5px;
        }
        .swap-icon-container{
            padding-right: 10px;
        }
        .swap-icon {
            margin-bottom: 5px;
        }

        .order-action{
            color: var(--primary-color);
            font-size: 10px;
            font-weight: bold;
            margin-top: 5px;
            align-items: center;
            margin-right: 30px;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
            &.remove{
                color: #DD0000;
            }
        }
    }

    .guideline-container {
        .traco{
            width: 5px;
            min-height: 205px;
            // position: absolute;
            // top: -82px;
            // left: -5px;
        }
        .tag{
            width: 15px;
            height: 15px;
            // position: absolute;
            // top: 0px;
            // left: -10px;
            margin-left: -5px;
            border-radius: 8px;
            margin-bottom: 2px;
            margin-top: 2px;
        }
    }
    // .placeholder {}
}


.order-item__merchant-name{
    font-weight: bold;
    color: #FF860A;
    margin-bottom: 2px;
}

.order-item__order-status{
    font-weight: bold;
    background-color: #FF860A;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 2px;
    display: inline-flex;
    font-size: 8px;
    text-align: center;
}