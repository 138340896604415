.route-detail-history-container{
    margin-bottom: 60px;
    .section-title{
        color: #626262;
        margin-bottom: 20px;
        svg {
            color: var(--primary-color);
        }
    }
    .route-item{
        margin-bottom: 20px;
        display: flex;
        .tag{
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-top: 3px;
            margin-right: 10px;

            &.route {
                background-color: var(--primary-color)
            }
        }
        .title{
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 500;
        }
        .value{
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 300;
        }
    }
}
