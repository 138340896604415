.order-moderation-page{
    background-color: #f5f5f5;
    width: 100%;
    min-height: 92vh;
    flex-direction: column;
    .table-content{
        margin-left: 0;
        width: 100%;
        height: 90vh;
        overflow-y: auto;
        overflow-x: hidden;
        @media (min-width: 1200px){
            margin-left: 25px;
        }
    }
    
}