.finance-extract-page {
    .row-title-container {
        .filters-input-stack {
            .item-input-form {
                margin: 0;
                margin-right: 20px;
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
