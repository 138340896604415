.top-row-container{
    text-align: left;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e1e1;
    position: relative;
    z-index: 10;
    @media (min-width: 1200px){
       box-shadow: -2px 0px 20px 0px rgba(0,0,0,0.4);
    }
    .row-item{
        display: flex;
        flex-wrap: wrap;
        @media (min-width: 1200px){
            align-items: center;
            margin-bottom: 0;
        }

        &.controls{
            margin:20px 0px;
        }
        .view-control-container{
            border: 1px solid var(--light-primary-color);
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            height: 30px;
            margin-left: auto;
            width: fit-content;
            margin: 0;
            margin-bottom: 20px;
            @media (min-width: 600px){
                margin-bottom: 0;
                margin-right: 30px;
            }
            .button-item{
                user-select: none;
                width: auto;
                padding: 0px 10px;
                font-size: 12px;
                overflow: hidden;
                color: var(--light-primary-color);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:first-child{
                    border-right: 1px solid var(--light-primary-color);
                }
                &.active{
                    background-color: var(--light-primary-color);
                    color: white;
                }
            }
        }

        .filter-container{
            @media (min-width: 1200px){
                display: flex;
                align-items: center;
            }
        }

        .filter-title{
            margin-top: 20px;
            margin-bottom: 10px;
            @media (min-width: 600px){
                margin-top: 0;
                width: unset;
                margin-bottom: 0;
                margin-right: 5px;
                margin-left: 15px;
            }
        }

        .date-info{
            margin-top: 0;
            font-weight: bold;
            font-size: 20px;
            @media (min-width: 1200px){
                margin-top: -30px;
            }
        }
        &.tags{
            justify-content: space-between;
        }
    }

    .left-column{
        box-sizing: border-box;
        margin-bottom: 20px;
        @media (min-width: 1200px){
            width: 45%;
        }
    }

    

    .top-row-title{
        color: black;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        .exchange-mall {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .buttons-container{
        display: flex;
        flex-wrap: wrap;
        @media (min-width: 1200px) {
            padding-top: 20px;
            flex-wrap: nowrap;
            align-items: flex-end;
        }
    }
    .search-input{
        width: 200px;
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;
        @media (min-width: 1200px){
            margin-bottom: 0;
        }
        input{
            width: 100%;
            height: 30px;
            border: 0;
            border: 1px solid var(--light-gray-color);
            border-radius: 8px;
            font-size: 14px;
            padding-left: 10px;
        }
        .icone{
            position: absolute;
            top: 10px;
            right: 5px;
        }
    }

    .filter-select {
        display: flex;
        align-items: center;
        margin-right: 20px;
        width: 200px;
        .item-input{
            select{
                height: 34px;
                width: 100%;
            }
        }
    }
}

.operation-row-link{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    color: white;
    padding: 10px 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: bold;
    height: 15px;
    transition: 0.2s;
    border: none;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    background-color: #fff;
    color: var(--primary-color);
    margin-right: 20px;
    svg {
        color: var(--primary-color);
    }
    @media (min-width: 1200px){
        border: 0;
        border-left: 1px solid var(--primary-color);
        margin-right: 0;
        &:first-child{
            padding-left: 0;
            border: 0;
        }
    }
    
    .label {
        margin-left: 10px;
        white-space: nowrap;
    }
    &:hover{
        opacity: 0.8;
    }
}

.small-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    background-color: var(--primary-color);
    color: white;
    padding: 10px 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: bold;
    transition: 0.2s;
    border: 1px solid var(--light-gray-color);
    margin-bottom: 20px;
    text-decoration: none;
    cursor: pointer;
    @media (min-width: 1200px){
        margin-left: 15px;
        margin-bottom: 0;
    }
    .label {
        margin-right: 10px;
        white-space: nowrap;
    }
    &:hover{
        opacity: 0.8;
    }
    &.outline{
        background-color: #fff;
        color: var(--primary-color);
        margin-right: 15px;
        svg {
            color: var(--primary-color);
        }
    }
    &.outline {
        background-color: white;
        color: var(--primary-color);
    }
}


.none-teste{
    display: none !important;
}

.auto-routing-container{
    display: flex;

}

.auto-routing-container__title{
    margin-right: 20px;
}