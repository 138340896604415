.table-row-item{
    width: 100%;
    border-left: 10px solid red;
    padding: 20px;
    padding-left: 15px;
    background-color: #fff;
    &:nth-child(odd){
        background: #f0f0f0;
    }
    @media (min-width: 1200px){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .table-row-content{
        font-size: 14px;
        margin-bottom: 10px;
        width: 100%;
        &.id{
            width: 5%;
        }
        &.store{
            width: 10%;
            font-weight: bold;
        }
        &.channel{
            width: 10%;
        }
        &.client{
            width: 30%;
            padding-left: 2%;
            padding-right: 2%;
            .client-address{
                font-weight: 500;
                margin-top: 3px;
            }
        }
        &.value{
            width: 15%;
        }
        &.button-single{
            width: 10%;
            color: var(--primary-color);
            cursor: pointer;
            font-size: 24px;
        }
        &.button-row{
            width: 35%;
            display: flex;
            .button-item{
                user-select: none;
                margin-right: 20px;
                background-color: var(--primary-color);
                padding: 10px 20px;
                border-radius: 4px;
                color: white;
                font-weight: bold;
                border: 1px solid var(--primary-color);
                cursor: pointer;
                &.outline{
                    background-color: transparent;
                    color: var(--primary-color);
                }
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
}