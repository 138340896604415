.item-input-form{
    margin-bottom: 10px;
    position: relative;
    label{
        font-size: 14px;
        font-weight: 500;
        width: auto;
        color: black;
        letter-spacing: 1px;
        margin-bottom: 5px;

        &.faded{
            color: grey;
        }
    }
    input, select, textarea{
        width: 100%;
        border: 0;
        border-radius: 4px;
        border: 1px solid var(--light-gray-color);
        height: 35px;
        font-size: 14px;
        margin-top: 5px;
        margin-top: 5px;
        padding: 0;
        padding-left: 10px;
        font-family: unset;

        &.faded {
            border: 1px solid grey;
            background-color: transparent;
        }
    }
    textarea{
        height: 80px;
        padding-top: 10px
    }
    .msg-erro{
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        margin-left: 3px;
        margin-top: 2px;
        svg {
            color: var(--red);
        }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        // color: white;
        opacity: 1; /* Firefox */
    }
    .password-trigger{
        position: absolute;
        top: 32px;
        right: 1px;
        cursor: pointer;
    }
}
