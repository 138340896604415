.modal-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999990;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
    opacity: 1;
    visibility: visible;
    
    .modal-image-bg {
        background-color: #00000020;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 31;
        
    }

    .modal-image-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
    }
    
    .modal-image-box {
        max-height: 1000px;
        max-width: 1000px;
        background-color: #ffffff;
        position: relative;
        z-index: 32;
        padding: 40px;
        border-radius: 5px;
        box-shadow: 0 2px 8px #00000020;
        transform: translate3d(0, -40, 0);
        transition: .4s;
    
        img {
            max-height: 1000px;
            max-width: 1000px;
        }
    }

    .modal-close {
        cursor: pointer;
        width: 1.8rem;
        margin-bottom: -32px;
        z-index: 35;
        svg {
            color: var(--light-primary-color);
        }
    }
}

