.administration-page{
    @media (min-width: 1200px){
        display: flex;
        flex-direction: column;
    }

    .content-container{
        display: flex;
        flex-direction: column;

        @media (min-width: 1200px){
            display: flex;
            flex-direction: row;
            height: calc(100vh - 147px);
        }

        .section-title{
            font-size: 22px;
            font-weight: bold;

        }

        .title-row{
            padding: 20px;
            border-bottom: 1px solid #e1e1e1;
            display: flex;
            @media (min-width:1200px){
                flex-direction: column;
                //align-items: center;
                //justify-content: start;
                padding: 30px;

                .title-row-content {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }


            .add-button{
                display: flex;
                align-items: center;
                justify-self: flex-end;
                color: var(--primary-color);
                cursor: pointer;
                margin-top: 20px;
                @media (min-width: 1200px){
                    margin-top: 0;
                }
            }
        }
        .left-column{
            box-sizing: border-box;
            @media (min-width: 1200px){
                width: 70%;
            }
            .roles{
                text-transform: uppercase;
                font-size: 11px;
                color: var(--primary-color);
                font-weight: bold;
                margin-bottom: 2px;
            }
            .status{
                text-transform: uppercase;
                font-size: 10px;
                margin-top: 2px;
            }
            .filter-row {
                display: flex;
                padding: 10px;
                padding-left: 0;
                align-items: center;
                .filter-item{
                    margin-right: 15px;
                }

                .filter-button{
                    background-color: var(--primary-color);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 20px;
                    border: 1px solid var(--primary-color);
                    margin-top: 20px;
                    margin-bottom: 20px;
                    display: inline-flex;
                    cursor: pointer;
                    @media (min-width: 1200px){
                        margin: 0;
                    }
                }
            }

            .listing{
                height: calc(100% - 117px);
                display: flex;
                flex-direction: column;
                overflow-y: auto;

                .loader-container {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .right-column{
            padding: 20px;
            @media (min-width: 1200px){
                width: 30%;
                padding: 30px;
            }

            .user-content{
                margin-top: 60px;
                .info-item{
                    display: flex;
                    margin-bottom: 20px;
                    .info-title{
                        text-align: left;
                        width: 100px;
                        color: #a1a1a1;
                        @media (min-width: 1200px){
                            margin-right: 30px;
                            text-align: right;
                        }
                    }
                }
            }

            .button-row{
                margin-top: 60px;
                @media (min-width: 1200px){
                    display: flex;
                }
                .button-item{
                    user-select: none;
                    background-color: var(--primary-color);
                    padding: 10px;
                    border-radius: 4px;
                    margin-right: 20px;
                    font-size: 14px;
                    color: white;
                    font-weight: bold;
                    border: 1px solid var(--primary-color);
                    cursor: pointer;
                    user-select: none;
                    text-align: center;
                    margin-bottom: 40px;
                    &.outline{
                        background-color: #fff;
                        color: var(--primary-color);
                    }
                }
            }
        }
    }
    .totals{
        font-size: 14px;
        margin-bottom: 16px;
        span {
            font-weight: bold;
        }
    }
}
