.deliverer-queue-table{
    @media (min-width: 1200px){
    }

    .mobile-tag{
        font-size: 14px;
        font-weight: bold;
        @media (min-width: 1200px){
            display: none;
        }
    }
    .table-row{
        padding: 15px;
        @media (min-width: 1200px){
            text-align: left;
            padding-left: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &.first{
            border-bottom: 1px solid #ccc;
            display: none;
            @media (min-width: 1200px){
                display: flex;
            }
            .table-item{
                color: #aaa;
                font-size: 12px;
            }
        }

        &:nth-child(even){
            background-color: #fff;
        }

        .table-item{
            font-size: 14px;
            margin-bottom: 10px;
            @media (min-width: 1200px){
                margin-bottom: 0;
            }
            &.id{
                @media (min-width: 1200px){
                    width: 10%;
                }
            }
            &.client{
                @media (min-width: 1200px){
                    width: 40%;
                }
                .name{
                    margin-bottom: 10px;
                    @media (min-width: 1200px){
                        color: #aaa;
                        margin-bottom: 0;
                    }
                }
                .address{
                    @media (min-width: 1200px){
                        font-weight: bold;
                        width: 90%;
                        font-size: 12px;
                    }
                }
            }
            &.order{
                @media (min-width: 1200px){
                    width: 25%;
                }
            }
            &.status{
                @media (min-width: 1200px){
                    width: 15%;
                }
            }
            &.total{
                @media (min-width: 1200px){
                    width: 10%;
                }
            }
            &.last{
                font-size: 20px;
                @media (min-width: 1200px){
                    width: 10%;
                }
                svg {
                    color: var(--light-primary-color);
                }
            }
        }
    }
}
