.datepicker-field{
    label {
        font-size: 14px;
        font-weight: 500;
        width: auto;
        color: black;
        letter-spacing: 1px;
        margin-bottom: 5px;
    }
    input {
        width: 100%;
        border: 0;
        border-radius: 4px;
        border: 1px solid var(--light-gray-color);
        height: 26px;
        margin: 0px 10px;
        padding-left: 10px;
        font-size: 14px;
        background-color: white;
    }

    &.field-height {
        margin-top: 24px;
        input {
            height: 33px;
            margin: 0px 0px;
            margin-top: 9px;
        }
    }
}

.datepicker-container{
    position: absolute;
    background-color: #FFF;
    border-width: 2px;
    z-index: 10;
    margin: 0px 10px;
}

.datepicker-spacing{
    margin-right: 13px;
    margin-top: 15px;
    @media (min-width: 1200px){
        margin-top: 0;
        margin-right: 23px;
    }
    .datepicker-field input{
        @media (max-width: 1200px){
            margin: 0;
        }
    }
}
