.deliverer-filter-container{
    padding: 20px;
    background-color: #f5f5f5;
    @media (min-width: 1200px){
        padding-bottom: 0;
        padding: 30px;
    }
    .filter-row{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .submit-filter{
            background-color: var(--primary-color);
            color: white;
            text-transform: uppercase;
            text-align: center;
            padding: 10px 40px;
            margin: 0;
            border-radius: 4px;
            font-weight: bold;
            cursor: pointer;
            transition: 0.4s;
            width: 200px;
            margin: auto;
            margin-top: 1px;
            margin-bottom: 30px;
            @media (min-width: 1200px){
                margin: unset;
                width: unset;
                margin-bottom: 30px;
            }
            &:hover{
                opacity: 0.7;
            }
        }
    }

    .filter-item{
        margin-bottom: 30px;
        &.name{
            input{
                @media (min-width: 1200px){
                    width: 170px;
                }
            }
        }

        &.cpf{
            input{
                @media (min-width: 1200px){
                    width: 110px;
                }
            }
        }

        &.cnpj{
            input{
                @media (min-width: 1200px){
                    width: 140px;
                }
            }
        }

        &.phone{
            &::before{
                display: none;
            }
            input{
                @media (min-width: 1200px){
                    width: 120px;
                }
            }
        }
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            margin-right: 20px;
        }
        .filter-label{
            margin-right: 10px;
            margin-bottom: 5px;
            @media (min-width: 1200px){
                margin-bottom: 0;
            };
            &.type{
                margin-right: 22px;
            }
        }
        .item-input-form{
            margin: 0;
        }
        label{
            display: none;
            width: 0;
            height: 0;
        }
        input, select{
            margin: 0;
            padding: 0;
            height: 35px;
            background-color: transparent;
            outline: none;
            font-size: 14px;
            color: black;
            @media (min-width: 1200px){
                padding: 0px 10px;
            }

        }
    }
}
