@font-face {
  font-family: 'Logaroo';
  src:
  url('../../assets/fonts/Logaroo.ttf?au6lgs') format('truetype'),
  url('../../assets/fonts/Logaroo.woff?au6lgs') format('woff'),
  url('../../assets/fonts/Logaroo.svg?au6lgs#Logaroo') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Logaroo' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.qr-code:before {
  content: "\e9e7";
}
i.picture:before {
  content: "\e9e8";
}
i.vertical-reverse:before {
  content: "\e9e1";
}
i.vertical-reverse-fill:before {
  content: "\e9e2";
}
i.left-rotate:before {
  content: "\e9e3";
}
i.right-rotate:before {
  content: "\e9e4";
}
i.horizontal-reverse:before {
  content: "\e9e5";
}
i.horizontal-reverse-fill:before {
  content: "\e9e6";
}
i.stopwatch:before {
  content: "\e9de";
}
i.redirect:before {
  content: "\e9df";
}
i.chats:before {
  content: "\e9db";
}
i.chats-fill:before {
  content: "\e9e0";
}
i.moon:before {
  content: "\e9dc";
}
i.sun:before {
  content: "\e9dd";
}
i.pin-code:before {
  content: "\e9da";
}
i.fixed:before {
  content: "\e9d8";
}
i.more-vertical:before {
  content: "\e9d9";
}
i.asterisk:before {
  content: "\e9d7";
}
i.hourglass:before {
  content: "\e9d5";
}
i.broken-link:before {
  content: "\e9cc";
}
i.route:before {
  content: "\e9ca";
}
i.cover:before {
  content: "\e9cb";
}
i.sort-alpha-up:before {
  content: "\e9c6";
}
i.sort-alpha-down:before {
  content: "\e9c7";
}
i.sort-up:before {
  content: "\e9c4";
}
i.sort-down:before {
  content: "\e9c5";
}
i.filter:before {
  content: "\e9c3";
}
i.lock:before {
  content: "\e971";
}
i.unlock:before {
  content: "\e9d6";
}
i.satellite:before {
  content: "\e9b2";
}
i.battery:before {
  content: "\e9b3";
}
i.wifi:before {
  content: "\e9b4";
}
i.android:before {
  content: "\e9b5";
}
i.logaroo:before {
  content: "\e97e";
}
i.delivery-mall:before {
  content: "\e944";
}
i.stretch:before {
  content: "\e931";
}
i.cutlery:before {
  content: "\e943";
}
i.search:before {
  content: "\e92e";
}
i.chain:before {
  content: "\e97f";
}
i.exchange:before {
  content: "\e980";
}
i.exchange-2:before {
  content: "\e945";
}
i.performance:before {
  content: "\e927";
}
i.bar-code:before {
  content: "\e902";
}
i.clip:before {
  content: "\e910";
}
i.ranges:before {
  content: "\e92b";
}
i.x:before {
  content: "\e93f";
}
i.plus:before {
  content: "\e9ab";
}
i.minus:before {
  content: "\e981";
}
i.menu:before {
  content: "\e921";
}
i.target:before {
  content: "\e937";
}
i.external:before {
  content: "\e9aa";
}
i.disconnect:before {
  content: "\e982";
}
i.disconnect-fill:before {
  content: "\e983";
}
i.robot:before {
  content: "\e9bc";
}
i.robot-fill:before {
  content: "\e9bd";
}
i.doc:before {
  content: "\e984";
}
i.doc-fill:before {
  content: "\e985";
}
i.map:before {
  content: "\e986";
}
i.map-fill:before {
  content: "\e987";
}
i.book:before {
  content: "\e988";
}
i.book-fill:before {
  content: "\e905";
}
i.calendar:before {
  content: "\e989";
}
i.calendar-fill:before {
  content: "\e906";
}
i.real:before {
  content: "\e98a";
}
i.circle-real:before {
  content: "\e922";
}
i.phone:before {
  content: "\e98b";
}
i.phone-fill:before {
  content: "\e928";
}
i.power:before {
  content: "\e9c1";
}
i.power-fill:before {
  content: "\e9c2";
}
i.location:before {
  content: "\e98c";
}
i.location-fill:before {
  content: "\e929";
}
i.pin:before {
  content: "\e9ac";
}
i.pin-fill:before {
  content: "\e9ad";
}
i.oven:before {
  content: "\e98d";
}
i.oven-fill:before {
  content: "\e92a";
}
i.tag-2:before {
  content: "\e98e";
}
i.tag-2-fill:before {
  content: "\e933";
}
i.sound-on:before {
  content: "\e9ae";
}
i.sound-off-fill:before {
  content: "\e9af";
}
i.sound-off:before {
  content: "\e9b0";
}
i.sound-on-fill:before {
  content: "\e9b1";
}
i.bars:before {
  content: "\e98f";
}
i.bars-fill:before {
  content: "\e914";
}
i.copy:before {
  content: "\e915";
}
i.flag:before {
  content: "\e990";
}
i.flag-fill:before {
  content: "\e91d";
}
i.go-out:before {
  content: "\e991";
}
i.go-out-fill:before {
  content: "\e91e";
}
i.ticket:before {
  content: "\e948";
}
i.ticket-fill:before {
  content: "\e947";
}
i.backpack:before {
  content: "\e95a";
}
i.backpack-fill:before {
  content: "\e95b";
}
i.bag:before {
  content: "\e95c";
}
i.bag-fill:before {
  content: "\e95d";
}
i.tag:before {
  content: "\e992";
}
i.tag-fill:before {
  content: "\e935";
}
i.tag-bag:before {
  content: "\e993";
}
i.tag-bag-fill:before {
  content: "\e934";
}
i.bell:before {
  content: "\e903";
}
i.bell-fill:before {
  content: "\e95e";
}
i.identification:before {
  content: "\e994";
}
i.identification-fill:before {
  content: "\e91f";
}
i.money:before {
  content: "\e94f";
}
i.card:before {
  content: "\e941";
}
i.card-fill:before {
  content: "\e995";
}
i.card-2-fill:before {
  content: "\e996";
}
i.card-2:before {
  content: "\e960";
}
i.wallet:before {
  content: "\e93e";
}
i.wallet-fill:before {
  content: "\e997";
}
i.pix:before {
  content: "\e9be";
}
i.pix-fill:before {
  content: "\e9bf";
}
i.note:before {
  content: "\e998";
}
i.note-fill:before {
  content: "\e925";
}
i.feed:before {
  content: "\e9cf";
}
i.feed-fill:before {
  content: "\e9d0";
}
i.edit:before {
  content: "\e91a";
}
i.circle-edit:before {
  content: "\e961";
}
i.edit-2:before {
  content: "\e999";
}
i.edit-2-fill:before {
  content: "\e962";
}
i.delivery:before {
  content: "\e963";
}
i.delivery-fill:before {
  content: "\e964";
}
i.e-mail:before {
  content: "\e965";
}
i.e-mail-fill:before {
  content: "\e918";
}
i.send-email:before {
  content: "\e99a";
}
i.send-email-fill:before {
  content: "\e966";
}
i.send:before {
  content: "\e99b";
}
i.send-fill:before {
  content: "\e92f";
}
i.home:before {
  content: "\e967";
}
i.home-fill:before {
  content: "\e968";
}
i.list:before {
  content: "\e969";
}
i.grid:before {
  content: "\e9d1";
}
i.grid-fill:before {
  content: "\e9d2";
}
i.list-fill:before {
  content: "\e96a";
}
i.list2:before {
  content: "\e9d3";
}
i.list2-fill:before {
  content: "\e9d4";
}
i.settings:before {
  content: "\e946";
}
i.settings-fill:before {
  content: "\e96b";
}
i.star:before {
  content: "\e96c";
}
i.star-fill:before {
  content: "\e96d";
}
i.stars:before {
  content: "\e99c";
}
i.stars-fill:before {
  content: "\e96e";
}
i.talk:before {
  content: "\e936";
}
i.talk-fill:before {
  content: "\e96f";
}
i.trash:before {
  content: "\e938";
}
i.trash-fill:before {
  content: "\e970";
}
i.tray:before {
  content: "\e949";
}
i.tray-fill:before {
  content: "\e9b6";
}
i.tray-2:before {
  content: "\e9c0";
}
i.tray-2-fill:before {
  content: "\e9b7";
}
i.almonds:before {
  content: "\e950";
}
i.chestnuts:before {
  content: "\e951";
}
i.crustaceans:before {
  content: "\e952";
}
i.dyes:before {
  content: "\e953";
}
i.eggs:before {
  content: "\e954";
}
i.fish:before {
  content: "\e955";
}
i.gluten:before {
  content: "\e956";
}
i.lactose:before {
  content: "\e957";
}
i.milk:before {
  content: "\e958";
}
i.soy:before {
  content: "\e959";
}
i.upload:before {
  content: "\e93a";
}
i.download:before {
  content: "\e917";
}
i.circle-arrow-up:before {
  content: "\e972";
}
i.circle-arrow-down:before {
  content: "\e973";
}
i.circle-arrow-left:before {
  content: "\e974";
}
i.circle-arrow-right:before {
  content: "\e90a";
}
i.arrow-up:before {
  content: "\e975";
}
i.arrow-down:before {
  content: "\e976";
}
i.arrow-left:before {
  content: "\e942";
}
i.arrow-right:before {
  content: "\e94a";
}
i.chevron-up:before {
  content: "\e94d";
}
i.chevron-down:before {
  content: "\e909";
}
i.chevron-left:before {
  content: "\e94b";
}
i.chevron-right:before {
  content: "\e94c";
}
i.info-fill:before {
  content: "\e9c8";
}
i.info:before {
  content: "\e9c9";
}
i.balloon-info:before {
  content: "\e99d";
}
i.balloon-info-fill:before {
  content: "\e901";
}
i.balloon-question:before {
  content: "\e940";
}
i.circle-check:before {
  content: "\e90b";
}
i.circle-x:before {
  content: "\e90f";
}
i.circle-plus:before {
  content: "\e90c";
}
i.circle-minus:before {
  content: "\e94e";
}
i.circle-question:before {
  content: "\e90d";
}
i.circle-refresh:before {
  content: "\e90e";
}
i.separete:before {
  content: "\e930";
}
i.approach:before {
  content: "\e900";
}
i.separete-horizontal:before {
  content: "\e9b8";
}
i.approach-horizontal:before {
  content: "\e9b9";
}
i.clock:before {
  content: "\e99e";
}
i.clock-fill:before {
  content: "\e911";
}
i.alarm:before {
  content: "\e9ba";
}
i.eye:before {
  content: "\e91c";
}
i.close-eye:before {
  content: "\e912";
}
i.circle-eye:before {
  content: "\e977";
}
i.circle-close-eye:before {
  content: "\e978";
}
i.close:before {
  content: "\e913";
}
i.expand:before {
  content: "\e91b";
}
i.order:before {
  content: "\e99f";
}
i.order-fill:before {
  content: "\e926";
}
i.import-order:before {
  content: "\e9a0";
}
i.import-order-fill:before {
  content: "\e920";
}
i.add-order:before {
  content: "\e924";
}
i.add-order-fill:before {
  content: "\e9a1";
}
i.check-order:before {
  content: "\e9a2";
}
i.check-order-fill:before {
  content: "\e9a3";
}
i.cancel-order:before {
  content: "\e907";
}
i.cancel-order-fill:before {
  content: "\e979";
}
i.edit-order:before {
  content: "\e919";
}
i.edit-order-fill:before {
  content: "\e97a";
}
i.search-order:before {
  content: "\e9a4";
}
i.search-order-fill:before {
  content: "\e92d";
}
i.detail-order:before {
  content: "\e9a5";
}
i.detail-order-fill:before {
  content: "\e916";
}
i.shop:before {
  content: "\e932";
}
i.shop-fill:before {
  content: "\e97b";
}
i.mall:before {
  content: "\e9cd";
}
i.mall-fill:before {
  content: "\e9ce";
}
i.rocket:before {
  content: "\e92c";
}
i.bike:before {
  content: "\e904";
}
i.motorcycle:before {
  content: "\e923";
}
i.motorcycle-fill:before {
  content: "\e9a6";
}
i.car:before {
  content: "\e908";
}
i.car-fill:before {
  content: "\e95f";
}
i.van:before {
  content: "\e93d";
}
i.van-fill:before {
  content: "\e97c";
}
i.truck:before {
  content: "\e939";
}
i.truck-fill:before {
  content: "\e97d";
}
i.picker:before {
  content: "\e9bb";
}
i.user:before {
  content: "\e9a7";
}
i.user-fill:before {
  content: "\e93c";
}
i.user-check:before {
  content: "\e93b";
}
i.users:before {
  content: "\e9a8";
}
i.users-fill:before {
  content: "\e9a9";
}
