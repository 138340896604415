.dropzone-input{
    margin-bottom: 10px;
    user-select: none;
    label{
        font-size: 14px;
        font-weight: 500;
        width: auto;
        color: black;
        letter-spacing: 1px;
        margin-bottom: 5px;

        &.faded{
            color: grey;
        }
    }
    img{
        display: block;
        width: 150px;
        height: auto;
    }
}
