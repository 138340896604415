.pos-screens-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    .pos-screen {
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
        background-color: white;
        margin: 0px 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        width: 20%;
        user-select: none;
        .check-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-left: 5px;
            padding-top: 5px;
            font-weight: bold;
            margin-bottom: 100px;
            border-bottom: 1px solid;
            padding-bottom: 10px;
            svg {
                border: 2px solid var(--gray-color);
                border-radius: 50%;
                font-size: 18px;
            }
        }
        .pos-screen-text {
            display: flex;
            justify-content: center;
            text-align: center;
            height: 200px;
            font-weight: bold;
        }
    }

    .exchange-screen-action {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            font-size: 22px;
            color: var(--light-primary-color);
        }
    }
}
