.default-button {
    padding: 10px 30px;
    background-color: transparent;
    border: 0;
    outline-color: var(--dark-primary-color);
    border-radius: 4px;
    color: black;
    font-size: 14px;
    width: 250px;
    margin-bottom: 20px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    border: 1px solid;
    cursor: pointer;

    .button-spinner {
        margin-left: 85px;
    }
}
