.order-card-container{
    border-radius: 8px;
    width: 100%;
    background-color: #fff;
    box-shadow: -2px 0px 20px 0px rgba(0,0,0,0.2);
    margin-bottom: 40px;
    overflow: hidden;
    &.route-added {
        opacity: 0.5;
    }

    .order-card-content{
        border-left: 15px solid #D0021B;
        width: 100%;
        height: 100%;
        padding: 10px;
        padding-top: 30px;
        .title{
            text-transform: uppercase;
            font-size: 12px;
            color: #333;
            margin-bottom: 5px;
            
            .id{
                color: var(--primary-color);
                font-weight: bold;
            }
            
            span{
                font-weight: bold;
            }
        }

        .restaurant-name{
            font-weight: bold;
            font-size: 22px;
        }
        .time-info{
            font-weight: bold;
            font-size: 16px;
        }
        .order-info{
            margin-bottom: 20px;
        }

        .client-info{
            margin-bottom: 20px;
            .client-name{
                width: 80%;
                word-break: break-word;
            }
        }

        .buttons-container{
            border-top: 1px solid #e8e8e8;
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            padding-right: 50px;
            .button-item{
                user-select: none;
                color: #8f8f8f !important;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
}
