.mall-creation-left-column {
    background-color: #f5f5f5;
    padding: 25px;
    overflow-y: auto;

    @media (min-width: 1200px) {
        width: 30%;
        height: 100%;
    }

    .mall-creation-form-container {
        width: 97%;

        .grouped-input-container {
            display: flex;
            justify-content: space-between;
            align-items: top;
        }
    }
}


.mall-creation-input-row {
    @media (min-width: 1200px) {
        display: flex;
        justify-content: space-between;

        >div {
            width: 48%;
        }
    }
}

.upload-image-container {
    padding-top: 1rem;
}

.colors-container {
    margin-top: 14px !important;
}