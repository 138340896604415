.route-detail-content-container{
    @media (min-width: 1200px){
        display: flex;
        width: 100%;
        height: 92vh;
    }
    .left-column{
        box-sizing: border-box;
        background-color: #f5f5f5;
        padding: 25px;
        overflow-y: auto;
        margin-bottom: 100px;
        @media (min-width: 1200px){
            width: 30%;
            height: 100%;
        }
        .buttons-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding-left: 5px;
            @media (min-width: 1200px){ 
                flex-direction: row;
            }
            button{
                background-color: var(--red);
                border-color: var(--red);
                margin:25px 0 40px;

                &:hover { background-color:#FFF; color: var(--red); }
            }
        }
        @media (min-width: 1200px){
            width: 30%;
            height: 100%;
        }
    }
    .right-column{
        background-color: #F5F5F5;
        .mapboxgl-map{
            margin: 0px 20px;
        }
        @media (min-width: 1200px){
            width: 70%;
            .mapboxgl-map{
                margin: 0px;
            }
        }
    }
}

.route-finish-button{
    padding-left: 6px;
    display: flex;
    align-items: center;
  
    margin-left: 5px;
    margin-bottom: 15px;
    .route-finish-button__circle{
        width: 30px;
        height: 30px;
        background-color: #ff860a;
        border-radius: 50%;
        margin-right: 10px;
    }
    .route-finish-button__text{
        border:1px solid #FF860A;
        color:#FF860A;
        font-size: 14px;
        font-weight: bold;
        padding:5px 15px;
        transition: 400ms;
        cursor: pointer;
        border-radius:5px;
        &:hover{
            background-color: #ff860a;
            color: #FFF;
            text-decoration: none;
        }
    }
}