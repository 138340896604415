.staffs-page{
    @media (min-width: 1200px){
        height: 100vh;
    }
    .content-container{
        height: 100%;

        .section-title{
            font-size: 22px;
            font-weight: bold;
           
        }

        .title-row{
            padding: 20px;
            border-bottom: 1px solid #e1e1e1;
            @media (min-width:1200px){
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 30px;
            }

            .add-button{
                color: var(--primary-color);
                cursor: pointer;
                margin-top: 20px;
                @media (min-width: 1200px){
                    margin-top: 0;
                }
            }
        }
        .left-column{
            box-sizing: border-box;
            @media (min-width: 1200px){
                width: 100%;
            }

            .filter-row {
                display: flex;
                padding: 10px;
                align-items: center;
                .filter-item{
                    margin-right: 15px;
                }

                .filter-button{
                    background-color: var(--primary-color);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 20px;
                    border: 1px solid var(--primary-color);
                    margin-top: 20px;
                    margin-bottom: 20px;
                    display: inline-flex;
                    cursor: pointer;
                    @media (min-width: 1200px){
                        margin: 0;
                    }
                }
            }

            .listing{
                height: calc(100vh - 230px);
                overflow: auto;

                .loader-container {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .totals{
        margin-left: 20px;
        font-size: 14px;
        margin-bottom: 16px;
        span {
            font-weight: bold;
        }
    }
}