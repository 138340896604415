.page-container{
    @media (min-width: 1200px){
        width: 100%;
        height: 100vh;
        overflow-y: hidden;
    }
    position: relative;
}

.content-container{
    width: 100%;
    position: relative;
    @media (min-width: 600px){
        display: flex;
        flex-direction: column;
    }
    
    @media (min-width: 1200px){
        flex-direction: row;
    }

    &.home{
        @media (min-width: 1200px){
            height: 65vh;
        }

        @media (min-width: 1260px){
            height: 65vh;
        }
    
        @media (min-width: 1600px){
            height: 75.5vh;
        }
    
        @media (min-width: 1900px){
            height: 81vh;
        }
    }
   
    .left-column{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;
        margin: 10px;
        width: calc(100% - 20px);
        @media (min-width: 1200px){
            margin: 0;
            width: 40%;
            overflow-x: hidden;
            overflow: hidden;
            border-right: 1px solid #eaeaea;
        }
    }
    .right-column{
        width: 100%;
        background-color: #F5F5F5;
        .mapboxgl-map{
            margin: 20px;
        }
        @media (min-width: 1199px){
            width: 60%;
            .mapboxgl-map{
                margin: 0px;
            }
        }
    }
}


