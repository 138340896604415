.operation-listing-production-container{
    display: flex;
    flex-direction: column;
    @media (min-width: 600px){
        flex-direction: row;
        height: 500px;
    }
    @media (min-width: 1200px){
        flex-direction: column;
        height: 100%;
    }
    .empty-message-container{
        margin-top: 50;
        display: flex;
        justify-content: center
    }

    .operation-row-container{
        height: 100%;
        
        @media (max-width: 1200px){
            flex: 1;
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        @media (min-width: 1200px){
            height: 50%;
        }
        .row-title-container{
            background-color: #F5F5F5;
            padding: 10px 25px;
            @media (min-width: 1200px){
                display: flex;
                justify-content: space-between;
                align-items: center;

            }

            .title-flex{
                display: flex;
                align-items: center;
                .icon{
                    margin-right: 10px;
                    svg{
                        color: var(--light-primary-color);
                    }
                }
            }
        }
        .orders-listing{
            flex: unset !important;
            overflow-y: auto;
            background-color: #f5f5f5;
            @media (max-width: 600px){
                height: 300px;
            }
            @media (max-width: 1200px){
                flex: 1;
            }
            @media (min-width: 1200px){
                height: 80%;
            }
            &:nth-child(2){
                background-color: #e8e8e8;
            }
        }
        .sort-button{
            width: 180px;            
            margin-top: 20px;
            @media (min-width: 1200px){
                margin-top: 0;
            }
        }
    }

    .order-row{
        padding: 15px;
        background-color: #FAFAFA;
        align-items: center;
        border-left: 10px solid;
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;

        }
        &:nth-child(odd){
            background-color: #f0f0f0;
        }
        .order-info{
            width: 100%;
            margin-bottom: 10px;
            @media (min-width: 1200px){
                width: 35%;
                margin-bottom: 0;
            }
        }
        .client-info{
            flex:1;
            margin-bottom: 20px;
            @media (min-width: 1200px){
                width: 25%;
                margin-bottom: 0;
            }
        }
        .time-info{
            min-width: 110px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            @media (min-width: 1200px) {
                width: 25%;
            }
            .timers{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .buttons{
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            svg {
                margin-bottom: 15px;
                &:first-child {
                    margin-right: 30px;
                }
            }
            @media (min-width: 1200px){
                flex-direction: column;
                justify-content: center;
                margin-top: 0;
                width: 10%;
                svg {
                    margin: 0;
                }
            }
        }

        .small-text{
            color: #aaa;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .black-text{
            font-weight: bold;
            color: #000;
            font-size: 14px;
            margin-bottom: 5px;
            &.address{
                font-weight: normal;
            }
            &.info{
                font-size: 22px;
                cursor: pointer;
                svg{
                    color: var(--light-primary-color);
                }
            }
            &.name{
                font-size: 18px;
            }

        }

    }
}
