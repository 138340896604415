.dynamic-rates-page-container{
    width: 100%;

    .dynamic-rates-card-listing{
        padding: 10px;
        background-color: #f5f5f5;
        &.padding-top{
            padding-top: 30px;
        }
        @media (min-width: 1200px){
            width: 50%;
            height: 90vh;
            padding-left: 25px;
            overflow: auto;
        }

        .column-title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
        }
    }

    .left-column{
        box-sizing: border-box;
        display: flex;
        height: 100%;
        padding: 0px;
        width: 500px;
    }
}
