.flex-table {
    width: 100%;
    tr, th {
        text-align: start !important;
    }
    .table-head-content {
        padding: 20px;
        border-bottom: 2px solid #e8e8e8;
        height: 30px;
        // padding-left: 50px;
        display: none;
        @media (min-width: 1200px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .table-head-item {
            font-size: 12px;

            &.button-row {
                width: 30%;
            }
        }
    }

    .flex-table-row-item {
        display: flex;
        flex-direction: column;
        // width: 100%;
        padding: 20px;
        // padding-left: 50px;
        background-color: #fff;
        &:nth-child(odd){
            background: #f0f0f0;
        }
        @media (min-width: 1200px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;
        }
        .flex-table-row-content {
            font-size: 14px;
            margin-bottom: 10px;
            width: auto;

            @media all and (max-width: 1200px) {
                width: 100% !important;
            }

            &.button-row {
                width: 30%;
                display: flex;
                .button-item {
                    user-select: none;
                    margin-right: 20px;
                    background-color: var(--primary-color);
                    padding: 10px 20px;
                    border-radius: 4px;
                    color: white;
                    font-weight: bold;
                    border: 1px solid var(--primary-color);
                    cursor: pointer;
                    &.outline {
                        background-color: transparent;
                        color: var(--primary-color);
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

}

