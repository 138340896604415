.payment-report-top-row-container {
    text-align: left;
    padding: 25px;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 100px;
    position: relative;
    z-index: 10;
    @media (min-width: 1200px){
       padding: 25px;
       height: 100px;
       box-shadow: -2px 0px 20px 0px rgba(0,0,0,0.4);
    }
    .row-item {
        margin-bottom: 15px;
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
        }
    }

    .inputs-row {
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
        }

        .filter-button{
            background-color: var(--primary-color);
            color: white;
            padding: 6px 20px;
            border-radius: 20px;
            border: 1px solid var(--primary-color);
            display: inline-flex;
            cursor: pointer;
            @media (min-width: 1200px){
                margin-left: 15px;
            }
        }

        .mobile-spacing{
            margin-bottom: 20px;
            @media (min-width: 1200px){
                margin-bottom: 0;
            }
        }
    }

    .left-column {
        box-sizing: border-box;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        @media (min-width: 1200px){
            margin-bottom: 0;
        }

        .back-icon{
            margin-right: 20px;
            cursor: pointer;
            svg {
                color: var(--light-primary-color)
            }
        }
    }

    .top-row-title{
        font-weight: bold;
        font-size: 22px;
    }
}
