.coverage-status {
    font-size: 12px;
    margin: 0;
    display: block;
}

.payment-area-filter{
    padding: 20px;
    padding-top: 0px;
}

.payment-area-title-row-container{
    padding: 1.25rem !important;
}

.clean-payment-filter-button{
    text-transform: uppercase;
    color: #ff8000;
    margin-top: 20px;
    margin-bottom: -10px;
    font-weight: bold;
    font-size: 10px;
    cursor: pointer;
    user-select: none;
}

.centered-payment-text{
    text-align: center !important;
}

.payment-area-title-row-container{
    padding: 20px;
    padding-top: 0;
}

.payment-area-title-row__title{
    font-weight: bold;
    margin-bottom: 5px;
}

.payment-area-title-row__amount{
    font-size: 12px;
    color: #ff8000;
    font-weight: bold;
}


.coverage-area-row{
    padding: 15px;
    background-color: #FAFAFA;
    align-items: center;
    border-left: 10px solid;
    @media (min-width: 1200px){
        display: flex;
        justify-content: space-between;

    }
    &:nth-child(odd){
        background-color: #f0f0f0;
    }
    .coverage-area-info{
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        @media (min-width: 1200px){
            width: 30%;
            margin-bottom: 0;
        }
    }
    .remove-content {
        &::before {
            display: none !important;
        }
    }
    .black-text{
        font-weight: bold;
        color: #000;
        font-size: 14px;
        margin-bottom: 5px;
        &.edit{
            
            font-size: 22px;
            cursor: pointer;
            svg{
                color: var(--light-primary-color);
            }
        }
        &.name{
            font-size: 18px;
        } 
    }
}