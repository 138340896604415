.view-control-container{
    border: 1px solid var(--light-primary-color);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    height: 30px;
    margin-left: auto;
    width: fit-content;
    .button-item{
        user-select: none;
        width: auto;
        padding: 0px 10px;
        font-size: 12px;
        overflow: hidden;
        color: var(--light-primary-color);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:first-child{
            border-right: 1px solid var(--light-primary-color);
        }
        &.active{
            background-color: var(--light-primary-color);
            color: white;
        }
    }
}
