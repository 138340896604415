.order-deliveryman-container{
    margin-bottom: 80px;
    .section-title{
        color: #626262;
        margin-bottom: 20px;
        svg {
            color: var(--primary-color);
        }
    }

    .deliveryman-info{
        display: flex;
        align-items: center;
        .photo-container{
            width: 60px;
            height: 60px;
            border-radius: 50px;
            overflow: hidden;
            margin-right: 20px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .info-container{
            .name{
                font-weight: bold;
                margin-bottom: 5px;
            }
            .vehicle{
                font-size: 12px;
            }
            .exchange{
                font-size: 14px;
            }
        }

        .change-button{
            margin-left: auto;
            color: var(--light-primary-color);
            margin-right: 10px;
            font-size: 20px;
            cursor: pointer;
        }
    }
}


.order-deliveryman__button{
    margin-top: 20px;
    border: 3px solid #ff860a;
    color: #ff860a;
    padding: 10px 20px;
    display: inline-flex;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
}