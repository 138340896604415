.item-input{
    position: relative;
    label{
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        width: auto;
        letter-spacing: 1px;
        position: absolute;
        left: 10px;
        top: -7px;
        background-color: #fff;
        padding: 0px 10px;
    }
    input, select{
        width: 100%;
        border: 0;
        border-radius: 4px;
        border: 1px solid var(--light-gray-color);
        height: 30px;
        padding-left: 10px;
        font-size: 14px;
        background-color: white;
        color: var(--dark-gray-color);
        outline-width: 0;
        outline: none;
        .white {
            background-color: blue
        }
    }
    .msg-erro{
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        margin-left: 3px;
        margin-top: 2px;
        svg {
            color: var(--secondary-color);
        }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--dark-gray-color);
        opacity: 1; /* Firefox */
    }
}
