.main {
    display: flex;
    position: relative;
    @media (min-width: 920px) {
        flex-flow: row nowrap;
        overflow: hidden !important;
    }

    .main-right{
        flex: 1;
        margin-top: 70px;
        @media (min-width: 1200px){
            margin-top: 0;
            margin-left: 80px;
        }
    }
    @keyframes show {
        from {
            opacity: 0;
            margin-top: -20px;
        }
        to {
            opacity: 1;
            margin-top: 0px;
        }
    }
    @keyframes close {
        from {
            opacity: 1;
            margin-top: 0px;
        }
        to {
            opacity: 0;
            margin-top: -20px;
        }
    }
}

.responsive-header{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
