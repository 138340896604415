.delivery-queue-page-content{
    padding: 15px;
    background-color: #f5f5f5;
    @media (min-width: 1200px){
        display: flex;
        padding: 0;
        height: calc(100vh - 81px);
        overflow: hidden;
    }

    .left-side{
        @media (min-width: 1200px){
            padding: 30px;
            width: 30%;
            padding-bottom: 50px;
            border-right: 1px solid #ccc;
            height: 100%;
            overflow: scroll;
        }

        @media (min-width: 1500px){
            padding: 30px;
            width: 20%;
        }
    }
    .title-row{
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-bottom: 30px;
        svg{
            margin-right: 20px;
            color: var(--light-primary-color);
        }
        .text{
            color: #6A6A69;
            font-weight: bold;
        }
    }

    .right-side{
        margin-top: 70px;
        @media (min-width: 1200px){
            margin-top: 0;
            padding-top: 30px;
            flex: 1;
            height: 100%;
            overflow: scroll;
        }
        .title-row{
            @media (min-width: 1200px){
                padding-left: 30px;
            }
        }
    }
}
