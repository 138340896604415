.request-delivery-left-column{
    background-color: #f5f5f5;
    padding: 25px;
    overflow-y: auto;
    @media (min-width: 1200px){
        width: 30%;
        height: 100%;
    }

    .request-delivery-form-container {
        width: 97%;
        .grouped-input-container {
            display: flex;
            justify-content: space-between;
            align-items: top;
        }
    }


}

.section-title-container {
    padding: 15px 0px;
    .section-title{
        font-weight: 600;
    }
}

.price-inputs-container{
    width: 97%;
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price-input{
        width: 45%;
    }
}
