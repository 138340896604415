.config-performance-times-page-container{
    @media (min-width: 1200px){
        width: 100%;
    }
    .left-column {
        box-sizing: border-box;
        height: 100%;
        padding-right: 40px;
        @media (min-width: 1200px){
            width: 30%;
        }
        .inputs-container {
            margin-bottom: 30px;
        }
        .title {
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 20px;
        }
        .subtitle {
            font-weight: bold;
            font-size: 18px;
        }
    }
}
