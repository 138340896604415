.deliverer-table {
    .deliverer-table-row {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        @media (min-width: 1200px) {
            display: flex;
            justify-content: space-between;
            padding: 30px;
            align-items: center;
        }

        &:nth-child(odd) {
            background-color: #eaeaea;
        }

        &:first-child {
            display: none;
            border-bottom: 1px solid #ababab;
            background-color: #f5f5f5;
            color: #ababab;
            margin-top: -45px;

            @media (min-width: 1200px) {
                display: flex;
            }

            .table-item {
                transition: 0.4s;
                cursor: pointer;

                &:hover {
                    color: var(--light-primary-color);
                }
            }
        }

        .table-item {
            width: 100%;
            margin-bottom: 15px;
            text-align: center;

            @media (min-width: 1200px) {
                text-align: left;
                padding: 0;
                margin-bottom: 0;
            }

            &.id {
                font-weight: bold;

                @media (min-width: 1200px) {
                    width: 10%;
                }
            }

            &.deliverer {
                @media (min-width: 1200px) {
                    width: 25%;
                }

                .deliverer-name {
                    font-weight: bold;
                }

                .deliverer-mail {
                    font-size: 12px;
                    color: #666;
                }
            }

            &.state {
                width: 15%;
                padding-left: 20px;
            }

            &.type {
                @media (min-width: 1200px) {
                    display: flex;
                    flex-direction: row;
                    gap: 0.25rem;
                    align-items: center;
                    width: 15%;
                }
            }

            &.status {
                @media (min-width: 1200px) {
                    width: 25%;
                }
            }

            &.createdAt {
                width: 15%;
            }

            &.buttons {
                margin-top: 10px;
                display: flex;
                justify-content: center;

                @media (min-width: 1200px) {
                    width: 25%;
                    margin-top: 0;
                    justify-content: flex-start;
                }

                .edit-deliverer-button-item {
                    color: var(--light-primary-color);
                    font-size: 14px;
                    font-weight: bold;
                    margin-right: 20px;
                    cursor: pointer;
                    transition: 0.4s;

                    &:hover {
                        color: var(--primary-color);
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    @media (min-width: 1200px) {
                        text-align: center;
                        margin-right: 40px;
                        font-size: 10px;
                    }

                    .icon {
                        font-size: 28px;
                    }
                }
            }
        }
    }
}
