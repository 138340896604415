.malls-table-row-item{
    width: 100%;
    padding: 20px;
    padding-left: 50px;
    background-color: #fff;
    &:nth-child(odd){
        background: #f0f0f0;
    }
    @media (min-width: 1200px){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .malls-table-row-content{
        font-size: 14px;
        margin-bottom: 10px;
        width: 100%;

        &.id{
            width: 5%;
        }
        &.label{
            width: 25%;
            font-weight: 500;
        }
        &.address{
            width: 30%;
        }
        &.status{
            width: 10%;
        }
        &.button-row{
            width: 30%;
            display: flex;
            align-items: center;
            .button-item{
                user-select: none;
                margin-right: 20px;
                background-color: var(--primary-color);
                padding: 10px 20px;
                border-radius: 4px;
                color: white;
                font-weight: bold;
                border: 1px solid var(--primary-color);
                cursor: pointer;
                &.outline{
                    background-color: transparent;
                    color: var(--primary-color);
                }
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
}


.malls-listing__config-click{
    font-size: 18px;
    color: #666;
    transition: 400ms;
    cursor: pointer;
    &:hover{
        opacity: 0.7;
    }
}