.row-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #F0F0F0;

    .text-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg{
            cursor: pointer;
            margin-right: 10px;
        }
        .row-title-text{
            font-size: 22px;
            font-weight: bold;
        }
    }

    .right-container{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media (min-width: 1200px){
            flex-direction: row;
        }
        .item-input-form {
            margin: 0 10px 10px;
            @media (min-width: 1200px) {
                margin: 0;
            }
        }
        .button-form {
            margin: 0 10px 10px;
            text-transform: initial;
            @media (min-width: 1200px){
                margin-bottom: 0;
            }
        }
    }
}
