.routes-running-list{
    position: relative;
    padding: 10px;
    background-color: #f5f5f5;
    @media (min-width: 1200px){
        overflow: auto;
    }

    .top-row{
        border-bottom: 1px solid #ccc;
        padding: 10px;
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
        }
        .title{
            font-size: 22px;
        }
    }

    .routes-running-table-row{
        padding: 15px 5px 10px;
        background-color: #fff;
        &:nth-child(odd){
            background: #f0f0f0;
        }
        @media (min-width: 1200px){
            display: flex;
            align-items: flex-start;
        }
        .routes-running-row-column{
            font-size: 12px;
            flex-direction: column;
            &.id{
                text-align: center;
                width: 10%;
            }
            &.orders{
                width: 35%;
                display: flex;
                flex-direction: column;

                .order-store-item{
                    font-weight: 600;
                    font-size: 14px;
                }
                .order-reference-id{
                    font-weight: bold;
                    color: var(--primary-color);
                }
                .order-info{
                    margin-top: 2px;
                    font-size: 10px;
                }
                .order-status{
                    font-size: 12px;
                    font-weight: 500;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    padding-right: 10px;
                }
            }
            &.deliveryman{
                width: 35%;
                .deliveryman-data{
                    display: flex;
                    align-items: center;
                    .photo{
                        height: 50px;
                        width: 50px;
                        border-radius: 50px;
                        object-fit: cover;
                        margin-right: 20px;
                    }
                    .info-route{
                        display: flex;
                        flex-direction: column;
                        .name{
                            font-weight: 600;
                        }
                        .modal{
                            font-weight: 300;
                            font-size: 10px;
                        }
                    }
                }
            }
            &.button{
                width: 20%;
                display: flex;
                .button-item{
                    user-select: none;
                    background-color: var(--primary-color);
                    padding: 5px 20px;
                    border-radius: 4px;
                    text-align: center;
                    color: white;
                    font-size: 10px;
                    font-weight: bold;
                    border: 1px solid var(--primary-color);
                    cursor: pointer;
                    &.outline{
                        background-color: transparent;
                        color: var(--primary-color);
                    }
                    &:hover{
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
