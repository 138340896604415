.integrator-item{
    padding: 20px;
    &:nth-child(odd){
        background-color: #f1f1f1;
    }
    @media (min-width: 1200px){
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left-column{
        box-sizing: border-box;
        .id-name{
            font-weight: bold;
            font-size: 18px;
        }
    }

    .integrator-right-column{
        display: flex;
        margin-top: 40px;
        @media (min-width: 1200px){
            margin-top: 0;
        }
        .button-item{
            cursor: pointer;
            user-select: none;
            color: var(--light-primary-color);
            text-align: center;
            margin-right: 60px;
            @media (min-width: 1200px){
                margin-left: 60px;
                margin-right: 0;

            }
            .icon{
                margin-bottom: 5px;
                font-size: 24px;
            }
        }
    }
}
