.offline-screen{
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#fff, 0.9);
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999999999;
    flex-direction: column;
    .icon-container{
        width: 100px;
        img{
            width: 100%;
        }
    }

    .main-title{
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        max-width: 300px;
        @media (min-width: 1200px){
            max-width: 450px;
        }
    }

    .small-text{
        font-size: 14px;
        width: 100%;
        max-width: 300px;
        text-align: center;
        margin-bottom: 30px;
        @media (min-width: 1200px){
            max-width: 450px;
        }
    }
}