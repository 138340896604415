.order-items-table {
    margin-top:10px;
    width:100%;
    th{
        font-weight:bold;
        font-weight:bold;
        text-transform:uppercase;
        color:#333;
        padding-bottom:5px;
    }

    thead {
        text-align: left;
    }

    tbody {
        text-align: left;
    }

    td{
        color:#CCC;
        color:#333;
    }
    .order-qty{
        text-align:center;
        width:20%;
    }
    .order-obs{
        color:#999;
        font-size:0.75em;
        padding-bottom:10px;
    }
}
