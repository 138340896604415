.allowed-deliveries-page{
    @media (min-width: 1200px){
        height: 100vh;
    }
    .content-container{
        height: 100%;

        .section-title{
            font-size: 22px;
            font-weight: bold;
            margin-bottom:10px;
           
        }

        .title-row{
            padding: 20px;
            border-bottom: 1px solid #e1e1e1;
            @media (min-width:1200px){
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 30px;
            }

            .add-button{
                color: var(--primary-color);
                cursor: pointer;
                margin-top: 20px;
                @media (min-width: 1200px){
                    margin-top: 0;
                }
            }
        }
        .left-column{
            box-sizing: border-box;
            @media (min-width: 1200px){
                width: 70%;
            }

            .listing{
                height: calc(100vh - 220px);
                overflow: auto;

                .loader-container {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .right-column{
            padding: 20px;
            @media (min-width: 1200px){
                width: 30%;
                padding: 30px;
            }

            .user-content{
                margin-top: 60px;
                .info-item{
                    display: flex;
                    margin-bottom: 20px;
                    .info-title{
                        text-align: left;
                        width: 100px;
                        color: #a1a1a1;
                        @media (min-width: 1200px){
                            margin-right: 30px;
                            text-align: right;
                        }
                    }
                    
                }
            }

            .button-row{
                margin-top: 60px;
                @media (min-width: 1200px){
                    display: flex;
                    
                }
                
                .button-item{
                    user-select: none;
                    background-color: var(--primary-color);
                    padding: 10px;
                    border-radius: 4px;
                    margin-right: 20px;
                    font-size: 14px;
                    color: white;
                    font-weight: bold;
                    border: 1px solid var(--primary-color);
                    cursor: pointer;
                    user-select: none;
                    text-align: center;
                    margin-bottom: 40px;
                    &.outline{
                        background-color: #fff;
                        color: var(--primary-color);
                    }
                }
            }
        }
    }
}