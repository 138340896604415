.performance-page-container{
    width: 100%;
    .performance-content-container{
        background-color: #f0f4f7;
        width: calc(100% - 40px);
        padding: 20px;
        flex-direction: column;
    }

    .box{
        padding: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #fff;
        margin-bottom: 20px;
        @media (min-width: 1200px){
            width: 33%;
        }
        &.center{
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
        }
        &.mid{
            @media (min-width: 1200px){
                width: 50%;
                margin-right: 40px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        &.full{
            @media (min-width: 1200px){
                width: 100%;
            }
        }

        &.table{
            @media (min-width: 1200px){
                width: unset;
                flex: 1;
            }
        }
        .info-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            border-bottom: 1px dashed #ccc;
            padding-bottom: 20px;

        }
        .title{
            font-size: 20px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 5px;
            color: #666;
        }
        .subtitle{
            font-size: 12px;
            color: #aaa;
        }
        .number{
            color: var(--primary-color);
            font-weight: bold;
            font-size: 18px;
            @media (min-width: 1200px){
                font-size: 22px;
            }
        }
    }

    .stores-checkboxes-container {
        margin-top: 30px;
    }

    .time-ranges-table{
        margin-top: 30px;
        width: 100%;
        th{
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
            color: #333;
            padding-bottom: 5px;
            text-align: center;
            &:first-child{
                width: 10%;
                text-align: left;
            }
            &:last-child{
                width: 5%;
            }
        }
        td{
            color: #333;
            padding: 10px;
        }
    }

    .chart-container{
        width: 100%;
        height: 100%;
        margin-top: 20px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .first-row{
        width: 100%;
        @media (min-width: 1200px){
            display: flex;
        }
    }

    .mid-container{
        margin-top: 20px;
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;
        }
    }

    .performance-table-container{
        .top-row{
            margin-bottom: 40px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
            @media (min-width: 1200px){
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: -20px;
                margin-right: -20px;
                padding-left: 20px;
                padding-right: 20px;
                margin-top: -20px;
            }
            .export-container {
                .export-button{
                    background-color: var(--primary-color);
                    color: white;
                    padding: 10px 20px;
                    border-radius: 20px;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    &:first-child {
                        margin-right: 10px;
                    }
                    display: inline-flex;
                    cursor: pointer;
                    @media (min-width: 1200px){
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    &.secondary {
                        background-color: white;
                        color: var(--primary-color);
                        border: 2px solid var(--primary-color);
                    }
                }
            }
        }

        .performance-table{
            .performance-table-row{
                @media (min-width: 1200px){
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                border-bottom: 1px dashed #ccc;
                padding-bottom: 20px;
                margin-bottom: 20px;
                &:first-child{
                    margin-bottom: 50px;
                    padding-bottom: 0;
                    border-bottom: 0;
                    display: none;
                    @media (min-width: 1200px){
                        display: flex;
                    }
                    .performance-table-row-item{
                        color: #94a0bf !important;
                        font-weight: bold;
                    }
                }
                .performance-table-row-item{
                    color: #666;
                    margin-bottom: 20px;
                    @media (min-width: 1200px){
                        text-align: center;
                        margin-bottom: 0;
                    }
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &.id{
                        text-align: left;
                        color: black;
                        @media (min-width: 1200px){
                            width: 9%;
                        }
                        .main{
                            font-size: 15px;
                            @media (min-width: 1200px){
                                margin-bottom: 0;
                            }
                        }
                        .sub{
                            font-size: 14px;
                            color: #666;
                            @media (min-width: 1200px){
                                font-size: 12px;
                            }
                        }
                    }
                    &.store{
                        @media (min-width: 1200px){
                            width: 15%;
                        }
                    }
                    &.client{
                        text-align: left;
                        color: black;
                        @media (min-width: 1200px){
                            width: 16%;
                        }
                        .name{
                            margin-bottom: 20px;
                            font-size: 16px;
                            @media (min-width: 1200px){
                                margin-bottom: 0;
                            }
                        }
                        .street{
                            font-size: 16px;
                            color: #666;
                            @media (min-width: 1200px){
                                font-size: 12px;
                            }
                        }
                    }
                    &.neighborhood{
                        @media (min-width: 1200px){
                            width: 10%;
                        }
                    }
                    &.order-time{
                        @media (min-width: 1200px){
                            width: 15%;
                        }
                    }
                    &.delivery-time{
                        @media (min-width: 1200px){
                            width: 13%;
                        }
                    }
                    &.channel{
                        @media (min-width: 1200px){
                            width: 7%;
                        }
                    }
                    &.delivery{
                        @media (min-width: 1200px){
                            width: 8%;
                        }
                    }
                    &.value{
                        font-weight: bold;
                        @media (min-width: 1200px){
                            width: 7%;
                        }

                    }
                }
            }
        }
    }

    .profit-content{
        margin-top: 60px;
        margin-bottom: 60px;
        .big-text{
            color: var(--primary-color);
            font-weight: bold;
            font-size: 36px;
            @media (min-width: 1200px){
                font-size: 64px;
            }
        }
    }

    .places-container{
        @media (min-width: 1200px){
            margin-top: 100px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .mid-info-container{
        margin-top: 50px;
        .mid-info-item{
            margin-bottom: 30px;
            .value{
                font-weight: bold;
                font-size: 24px;
                color: var(--primary-color);
                margin-bottom: 5px;
            }
            .label{
                font-size: 16px;
                color: #666;
            }
        }
    }

    .mobile-label{
        margin-right: 5px;
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        color: #666;
        @media (min-width: 1200px){
            display: none;
        }
    }
    .button-container{
        text-align: center;
        margin-top: 30px;
    }
    .load-button{
        border: 2px solid var(--primary-color);
        color: var(--primary-color);
        padding: 5px 20px;
        border-radius: 20px;
        display: inline-flex;
        justify-content: center;
        align-self: center;
        transition: 0.4s;
        cursor: pointer;
    }
}
