.top-row-default-container{
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    position: relative;
    padding-top: 20px;
    z-index: 10;
    @media (min-width: 1200px){
       padding: 25px;
       box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    }
    .row-item{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .left-column{
        box-sizing: border-box;
        margin-bottom: 20px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        @media (min-width: 1200px){
            margin-bottom: 0;
            padding-left: 0;
        }

    }

    .right-column{
        padding-left: 20px;
        @media (min-width: 1200px){
            display: flex;
            align-items: flex-end;
            padding-left: 0;
        }

        .rounded {
            border-radius: 8px;
        }
    }

    .top-row-title{
        font-weight: bold;
        font-size: 22px;
    }

    .top-row-back-button{
        font-weight: bold;
        font-size: 22px;
        // margin-left: 20px;
        margin-right: 20px;
        svg{
            color: var(--light-primary-color)
        }
    }

    .interval-select{
        display: flex;
        align-items: center;
        padding-right: 50px;
        .label{
            color: var(--primary-color);
            font-weight: bold;
            margin-right: 20px;
            font-size: 18px;
        }
        .select-container{
            position: relative;
            width: 100px;
            .icon{
                position: absolute;
                right: 0px;
                top: 6px;
                color: var(--primary-color);
                font-size: 14px;
                pointer-events: none;
            }
            select{
                cursor: pointer;
                border-radius: 1;
                background-color: transparent;
                appearance: none;
                border: 0;
                font-size: 16px;
                padding: 0;
                margin: 0;
                padding-top: 3px;
                outline-width: 0;
            }
        }
    }

    .deliverer-tag-item{
        display: flex;
        align-items: center;
        .icon{
            color: var(--primary-color);
            font-size: 22px;
            margin-right: 10px;
        }
        .number{
            font-size: 32px;
            margin-right: 20px;
            font-weight: bold;
        }
        .tag-title{
            font-weight: bold;
            font-size: 12px;
            width: 100px;
        }
    }
}
