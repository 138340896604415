.rate-label{
    width: 97%;
}

.section-title-container {
    padding: 15px 0px;
    .section-title{
        font-weight: 600;
    }
}

.price-inputs-container{
    width: 97%;
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price-input{
        width: 45%;
    }
}