.stores-table-head-container{ 
    width: 100%;
    padding: 20px;
    border-bottom: 2px solid #e8e8e8;
    height: 30px;
    padding-left: 50px;
    display: none;
    @media (min-width: 1200px){
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .table-head-item{
        font-size: 12px;
       
        &.id{
            width: 5%;
        }
        &.label{
            width: 15%;
        }
        &.type{
            width: 15%;
        }
        &.category{
            width: 15%;
        }
        &.status{
            width: 8%;
        }
        &.dynamic{
            width: 10%;
        }
        &.button-single{
            width: 10%;
            color: #ababab;
        }
        &.button-row{
            width: 30%;
        }
    }
}