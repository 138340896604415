.individual-payment-report-page{
    .individual-payment-report-page-content{
        padding: 20px;
        background-color: #f5f5f5;
        @media (min-width: 1200px){
            min-height: 82vh;
            padding-top: 50px;
            display: flex;
            justify-content: space-between;
            padding-right: 115px;
            align-items: flex-start;
        }
        @media (min-width: 1280px){
            padding-right: 20px;
        }
    }

    .button-container{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
        .load-button{
            width: 150px;
            text-align: center;
        }
    }

    .box{
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 20px;
        &.left-side{
            padding: 15px;
            @media (min-width: 1200px){
                padding: 25px;
                width: 25%;
            }
            @media (min-width: 1600px){
                width: 23%;
            }
        }
        &.right-side{
            @media (min-width: 1200px){
                overflow: hidden;
                width: 75%;
                margin-left: 20px;
            }
        }
    }

    .top-row{
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 10px;
        margin-bottom: 30px;
        .user-image{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 30px;
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .user-name{
            max-width: 60%;
            flex: 1;
            font-size: 14px;
            font-weight: bold;
            @media (min-width: 1200px){
                font-size: 24px;
            }
        }
    }

    .info-block{
        margin-bottom: 20px;

        .export-button{
            background-color: var(--primary-color);
            color: white;
            padding: 10px 20px;
            border-radius: 20px;
            margin-top: 50px;
            margin-bottom: 20px;
            display: inline-flex;
            cursor: pointer;
            @media (min-width: 1200px){
                margin: 0;
            }
        }
    }

    .orders-row{
        padding: 20px;
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            padding-right: 0;
            padding-left: 20px;
            font-size: 14px;
            padding-bottom: 10px;
        }
        &:nth-child(even){
            background-color: #f1f1f1;
        }
        &.title{
            border-bottom: 1px solid #aaa;
            padding-bottom: 10px;
            margin-bottom: 20px;
            color: #aaa;
            background-color: #f1f1f1;
            padding: 15px;
            padding-left: 20px;
            padding-right: 0;
            display: none;
            @media (min-width: 1200px){
                display: flex;
            }
        }
        .row-item{
            margin-bottom: 10px;
            @media (min-width: 1200px){
                margin-bottom: 0;
            }
            &.order-id{
                color: #696969;
                @media (min-width: 1200px){
                    width: 10%;
                }
            }

            &.store{
                font-weight: bold;
                @media (min-width: 1200px){
                    width: 20%;
                }
            }

            &.biker{
                @media (min-width: 1200px){
                    width: 30%;
                }
            }

            &.address{
                @media (min-width: 1200px){
                    width: 40%;
                    padding-right: 20px;
                    font-size: 12px;
                }
            }

            &.order-date{
                @media (min-width: 1200px){
                    width: 20%;
                }
            }
            &.value{
                @media (min-width: 1200px){
                    width: 15%;
                }
            }
            .neighborhood{
                font-weight: bold;
                font-size: 14px;
            }
        }
    }

    .table-content-container{
        @media (min-width: 1200px){

        }
    }


}
