.checkbox-input{
    margin-bottom: 10px;
    user-select: none;
    label{
        font-size: 14px;
        display: flex;
        align-items: center;
        input {
            display: none;
        }
        .check-container{
            width: 14px;
            height: 14px;
            border: 2px solid var(--light-primary-color);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            svg {
                color: var(--primary-color);
                font-size: 10px;
            }
        }
    }
}
