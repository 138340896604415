.marketplace-management-page-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    height: calc(100vh - 70px);

    @media (min-width: 1200px){
        // width: 100%;
    }

    .card-button-listing{
        margin-top: 20px;
        margin-bottom: 70px;
        padding-right: 40px;
        .card-button-item{
            user-select: none;
            width: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 20px;
            border: 2px solid transparent;
            transition: 0.4s;
            cursor: pointer;
            text-decoration: none;
            color: black;
            &.active{
                background-color: var(--primary-color);
                .text {
                    font-weight: bold;
                    color: white;
                }
            }
            &:hover{
                border-color: var(--light-primary-color);
                .icon{
                    transform: scale(1.4);
                    color: var(--light-primary-color);
                }
            }
            .text {
                font-weight: bold;
            }
            .icon{
                transition: 0.4s;
                margin-right: 20px;
                font-size: 26px;
                width: 30px;
                text-align: center;
                color: var(--light-primary-color);
            }
        }
    }

    .right-column{
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        display: flex;
        flex: 1;
        background-color: #F0F0F0;

    }

    .info-view-container {
        padding: 20px;
        .info-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            .info-item {
                margin-right: 60px;
                margin-bottom: 30px;
                .label {
                    margin-bottom: 5px;
                    font-size: 14px;
                    color: #666;
                }
                .value {
                    font-size: 16px;
                }
            }
        }

        .radio-group{
            margin-bottom: 30px;
            label {
                font-size: 14px;
                font-weight: 500;
                width: auto;
                color: black;
                letter-spacing: 1px;

                &.faded{
                    color: grey;
                }
            }
            .radio-group-row {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
            }
        }
    }

    .marketplace-form-container{
        padding: 20px;

        .inputs-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
            &.col-2 {
                .item-input-form{
                    flex-basis: 48%;
                }
            }
            &.col-3 {
                .item-input-form{
                    flex-basis: 48%;
                    @media (min-width: 1200px){
                        flex-basis: 32%;
                    }
                }
            }
            &.col-4 {
                .item-input-form{
                    flex-basis: 48%;
                    @media (min-width: 1200px){
                        flex-basis: 23%;
                    }
                }
            }

            .checkbox-group{
                margin-top: 25px;
                margin-bottom: 10px;
                margin-right: 30px;
                label {
                    font-size: 14px;
                    font-weight: 500;
                    width: auto;
                    color: black;
                    letter-spacing: 1px;

                    &.faded{
                        color: grey;
                    }
                }
                .checkbox-group-row {
                    margin-top: 15px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }

            .radio-group{
                margin-top: 25px;
                margin-bottom: 10px;
                margin-right: 30px;
                label {
                    font-size: 14px;
                    font-weight: 500;
                    width: auto;
                    color: black;
                    letter-spacing: 1px;
                    margin-bottom: 5px;

                    &.faded{
                        color: grey;
                    }
                }
                .radio-group-row {
                    margin-top: 15px;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
}
