.manage-deliverers{
    padding: 20px;
    background-color: #f0f4f7;
    @media (min-width: 1200px){
        padding: 25px;
        padding-top: 50px;
    }
    .manage-deliverers-card-listing{
        background-color: #fff;
        margin-bottom: 10px;
        @media (min-width: 1200px){
            padding: 25px;
            margin: -25px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
        }
    }

    .ranking-bikers-container{
        background-color: #fff;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        .ranking-bikers-top-row {
            display: flex;
            flex-direction: row;
            padding: 20px 20px;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 10px;
            border: solid #c6c6c6;
            border-width: 0px 0px 0.5px 0px;

            .title {
                color: var(--primary-color);
                font-weight: bold;
                font-size: 20px;
            }

            .view-control{
                border: 1px solid var(--light-primary-color);
                border-radius: 8px;
                overflow: hidden;
                display: flex;
                height: 30px;
                margin-left: auto;
                .button-item{
                    user-select: none;
                    width: 75px;
                    font-size: 12px;
                    overflow: hidden;
                    color: var(--light-primary-color);
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    &:first-child{
                        border-right: 1px solid var(--light-primary-color);
                    }
                    &.active{
                        background-color: var(--light-primary-color);
                        color: white;
                    }
                }
            }
        }

        .ranking-bikers-container-list{
            display: flex;
            flex-direction: column;
            padding: 20px;
            @media (min-width: 1200px){
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .ranking-bikers-list{
                margin-bottom: 20px;
                @media (min-width: 1200px){
                    width: 24%;
                }

                .ranking-bikers-title{
                    font-size: 20px;
                    font-weight: 500;
                    margin: 0;
                    margin-bottom: 20px;
                    color: var(--primary-color);
                }
                .biker-ranking-item{
                    display: flex;
                    align-items: center;
                    background-color: #fff;
                    border: solid #c6c6c6;
                    border-width: 0px 0px 0.5px 0px;
                    padding: 20px 0px;
                    @media (min-width: 1200px){
                        display: flex;
                    }

                    .left-side-container{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;

                        @media (min-width: 1200px){
                            margin-bottom: 0;
                        }

                        .biker-position{
                            color: var(--dark-gray-color);
                            font-weight: bold;
                            font-size: 28px;
                            margin-right: 20px;
                        }
                        .info-row{
                            width: 100%;
                            .biker-name{
                                font-weight: bold;
                                font-size: 14px;
                            }
                            .biker-vehicle{
                                color: #ababab;
                                text-transform: uppercase;
                                font-weight: bold;
                                font-size: 10px;
                            }
                        }
                        .time{
                            justify-self: flex-end;
                            font-size: 14px;
                            margin-left: auto;
                            margin-right: 20px;
                            color: var(--gray-color);
                        }
                    }
                }
            }
        }
    }
}
