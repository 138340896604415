.order-detail-content-container{
    @media (min-width: 1200px){
        display: flex;
        width: 100%;
        height: 92vh;
    }
    .left-column{
        box-sizing: border-box;
        background-color: #fff;
        padding: 25px;
        overflow-y: auto;
        margin-bottom: 100px;
        @media (min-width: 1200px){
            width: 30%;
            height: 100%;
        }
        .switch-view-mode-container {
            display: flex;
            align-items: flex-end;
        }
        .icon{
            color: var(--light-primary-color);
        }
        .buttons-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 60px;
        }
    }
    .right-column{
        background-color: #F5F5F5;
        .mapboxgl-map{
            margin: 0px 20px;
        }
        @media (min-width: 1200px){
            width: 70%;
            .mapboxgl-map{
                margin: 0px;
            }
        }
    }
}
