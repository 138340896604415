.mall-management-page-container{
    @media (min-width: 1200px){
        width: 100%;
    }
    
    .title-row{
        font-size: 24px;
        margin-bottom: 20px;
        display: flex;
        .text{
            margin-left: 20px;
        }
        .mall-address{
            font-size: 16px;
            color: #ababab;
            margin-top: 10px;
        }
    }

    .card-button-listing{
        margin-top: 70px;
        margin-bottom: 70px;
        padding-right: 40px;
        .card-button-item{
            user-select: none;
            width: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 20px;
            border: 2px solid transparent;
            transition: 0.4s;
            cursor: pointer;
            text-decoration: none;
            color: black;
            &:hover{
                border-color: var(--light-primary-color);
                .icon{
                    transform: scale(1.4);
                    color: var(--light-primary-color);
                }
            }
            .icon{
                transition: 0.4s;
                margin-right: 20px;
                font-size: 26px;
                width: 30px;
                text-align: center;
                color: var(--light-primary-color);

                img, svg, i {
                    transition: 0.4s;
                    margin-right: 20px;
                    font-size: 26px;
                    width: 30px;
                    text-align: center;
                    color: var(--light-primary-color);
                }
            }
        }
    }
    
}