.deliverer-info-item{
    margin-bottom: 20px;
    margin-right: 20px;
    .label{
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #aaa;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 10px;
    }
    .value{
        font-size: 14px;
    }
    .error{
        color: var(--red);
    }
}
