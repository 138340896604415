.stone-management-page-container{
    @media (min-width: 1200px){
        width: 100%;
    }

    .card-button-listing{
        margin-top: 20px;
        margin-bottom: 70px;
        padding-right: 40px;
        .card-button-item{
            user-select: none;
            width: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 20px;
            border: 2px solid transparent;
            transition: 0.4s;
            cursor: pointer;
            text-decoration: none;
            color: black;
            &.active{
                background-color: var(--primary-color);
                .text {
                    font-weight: bold;
                    color: white;
                }
            }
            &:hover{
                border-color: var(--light-primary-color);
                .icon{
                    transform: scale(1.4);
                    color: var(--light-primary-color);
                }
            }
            .text {
                font-weight: bold;
            }
            .icon{
                transition: 0.4s;
                margin-right: 20px;
                font-size: 26px;
                width: 30px;
                text-align: center;
                color: var(--light-primary-color);
            }
        }
    }

    .right-column{
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.info-view-container {
    padding: 20px;
    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        .info-item {
            margin-right: 60px;
            margin-bottom: 30px;
            .label {
                margin-bottom: 5px;
                font-size: 14px;
                color: #666;
            }
            .value {
                font-size: 16px;
            }
        }

        .card-rate-type {
            font-weight: bold;
            margin-top: 20px;
            min-width: 150px;
        }
    }

    .radio-group{
        margin-bottom: 30px;
        label {
            font-size: 14px;
            font-weight: 500;
            width: auto;
            color: black;
            letter-spacing: 1px;

            &.faded{
                color: grey;
            }
        }
        .radio-group-row {
            margin-top: 5px;
            display: flex;
            flex-direction: row;
        }
    }
}

.stone-form-container{
    padding: 20px;

    .inputs-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        &.col-2 {
            .item-input-form{
                flex-basis: 48%;
            }
        }
        &.col-3 {
            .item-input-form{
                flex-basis: 48%;
                @media (min-width: 1200px){
                    flex-basis: 32%;
                }
            }

            .bank-info-input{
                flex-basis: 48%;
                @media (min-width: 1200px){
                    flex-basis: 32%;
                }
            }
        }
        &.col-4 {
            .item-input-form{
                flex-basis: 48%;
                @media (min-width: 1200px){
                    flex-basis: 23%;
                }
            }
        }

        .bank-info-input{
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .item-input-form{
                flex-basis: 85%;
                &:last-child{
                    margin-left: 20px;
                    margin-right: 5px;
                    flex-basis: 15%;
                }
            }
        }

        .radio-group{
            margin-top: 25px;
            margin-bottom: 10px;
            label {
                font-size: 14px;
                font-weight: 500;
                width: auto;
                color: black;
                letter-spacing: 1px;
                margin-bottom: 5px;

                &.faded{
                    color: grey;
                }
            }
            .radio-group-row {
                margin-top: 15px;
                display: flex;
                flex-direction: row;
            }
        }
    }

    .cards-rate-container{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .cards-rate-label-types{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            top: 90px;
            label {
                font-size: 16px;
                font-weight: bold;
                padding: 10px;
                margin-bottom: 14px;
                white-space: nowrap;
            }
        }

        .cards-rate-group{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            .cards-rate-group-item{
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    margin: 10px 0px;
                    height: 60px;
                }

                .cards-rate-input-items{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .item-input-form{
                        margin-top: 10px;
                        margin-bottom: 0px;
                        width: 80%;
                    }
                }
            }
        }
    }
}

