.small-order-card{
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    overflow: hidden;
    border-left: 10px solid;
    margin-bottom: 20px;
    .order-number{
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .client-name{
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .client-address{
        font-size: 14px;
        margin-bottom: 20px;
    }
    .total-time{
        font-weight: bold;
        font-size: 12px;
    }
}