
.route-created-modal{
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    z-index: 1;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 150px;
    @media (min-width: 1200px){
        align-items: center;
        padding-bottom: 0;
    }
    .modal-content{
        text-align: center;
        .title-route{
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .small-link{
            cursor: pointer;
            color: var(--primary-color);
            font-size: 12px;
            font-weight: bold;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}