.modal-create-staff {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 2rem;
    transition: 0.2s;
    z-index: 30;
    opacity: 0;
    visibility: hidden;

    .Modal-header {
        padding-bottom: 1.5rem;
        // margin-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // @include media(700px) {
        //     padding-bottom: 2rem;
        // }
    }

    .Modal-box {
        transition: 0.2s;

        .Modal-body {
            .inputs-container {
                width: 97%;

                .inputs-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .item-input-form {
                        width: 47%;
                    }
                }
                .stores-checkbox-group {
                    margin-top: 15px;
                    height: 200px;
                    overflow: hidden;
                    overflow-y: scroll;
                    .stores-checkbox-container {
                        margin-top: 10px;
                        .stores-label {
                            font-size: 14px;
                            font-weight: 500;
                            width: auto;
                            color: black;
                            letter-spacing: 1px;
                            margin-bottom: 5px;
    
                            &.faded{
                                color: grey;
                            }
                        }
    
                        .stores-checkbox-group {
                            margin-top: 15px;
                            height: 200px;
                            overflow: hidden;
                            overflow-y: scroll;
                        }
                    }
                }
            }

            .Modal-buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;

                .Modal-button {
                    padding: 10px 30px;
                    border-radius: 5px;
                    border-style: solid;
                    color: black;
                    font-size: 14px;
                    width: 48%;
                    margin-top: 20px;
                    // margin-bottom: 10px;
                    font-weight: bold;
                    text-decoration: none;
                    text-align: center;
                    cursor: pointer;

                    &.primary {
                        color: white;
                        border-color: var(--primary-color);
                        background-color: var(--primary-color);
                    }

                    &.outline {
                        color: var(--primary-color);
                        border-color: var(--primary-color);
                        border-width: 2px;
                    }
                }
            }
        }
    }

    &.is-active {
        .Modal-box {
            transform: translate3d(0, 0, 0);
        }

        opacity: 1;
        visibility: visible;
    }

    &.small {
        .Modal-header {
            padding-bottom: 1.5rem;
        }
        .Modal-title {
            font-size: 1.8rem;
        }
        .Modal-close {
            width: 1.8rem;
        }
        .Modal-box {
            max-width: 40rem;
        }
    }

    .Modal-bg {
        background-color: rgba(black, 0.4);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 31;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Modal-box {
        background-color: white;
        position: relative;
        z-index: 32;
        padding: 2rem;
        margin: auto;
        border-radius: 4px;
        box-shadow: 0 3px 10px rgba(black, 0.2);
        max-width: 82rem;
        width: 350px;
    }

    .Modal-title {
        font-size: 1.4rem;
        font-weight: 600;
        // line-height: 1.2;
        // @include media(700px) {
        //     font-size: 2.4rem;
        // }
    }

    .Modal-close {
        cursor: pointer;
        // width: 1.8rem;
        // @include media(700px) {
        //     width: 2rem;
        // }
    }
}
