.button-link-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .button-link {
        outline: 0;
        border: none;
        background-color: transparent;
        padding: 5px 0px;
        color: var(--primary-color);
        font-size: 12px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-weight: bold;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
    }
}
