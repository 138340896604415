.button-form {
    padding: 10px 30px;
    background-color: transparent;
    border: 2px solid;
    border-color: var(--primary-color);
    border-radius: 5px;
    color: white;
    background-color: var(--primary-color);
    font-size: 14px;
    min-width: 210px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;

    .button-spinner {
        margin-left: 65px;
    }

    &.secondary {
        color: var(--primary-color);
        background-color: white;
    }
}

