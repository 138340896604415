.deliverer-card{
    padding: 20px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 40px;
    .card-box{
        width: 100%;
        height: 65px;
        background-color: var(--primary-color);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .deliverer-title-row{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        z-index: 2;
        position: relative;
        .photo{
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            border: 4px solid #fff;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .number{
            font-size: 22px;
            color: #fff;
            font-weight: bold;
        }
    }

    .deliverer-info{
        margin-top: 20px;
        border-bottom: 1px solid #eee;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .deliverer-name{
            color: #000;
            font-weight: bold;
        }
        .deliverer-vehicle{
            font-size: 12px;
            color: #aaa;
        }
    }

    .info-item{
        margin-bottom: 20px;
        .label{
            text-transform: uppercase;
            font-size: 10px;
            color: #aaa;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .content{
            font-weight: bold;
            color: #000;
            font-size: 12px;
        }
    }

    .info-row{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
