.order-detail-history-container{
    margin-bottom: 60px;
    .section-title{
        color: #626262;
        margin-bottom: 20px;
        svg {
            color: var(--primary-color);
        }
    }
    .history-item{
        margin-bottom: 20px;
        .title{
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 500;
        }
        .value{
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 300;
        }
    }
}