.order-detail-info-container{
    padding-top: 40px;
    .info-item{
        margin-bottom: 20px;

        .title{
            text-transform: uppercase;
            font-size: 10px;
            color: #979797;
            font-weight: bold;
            margin-bottom: 5px;

            &.red{
                color: brown;
            }
        }
        .value{
            margin-bottom: 5px;
            &.restaurant{
                font-weight: bold;
                font-size: 20px;
            }
            display: flex;
            .quantidade{
                margin-right: 10px;
                width: 25px;
            }
            &.items-description {
                flex-direction: column;
                .item{
                    display: flex;
                }
                .sub-items {
                    margin: 5px 0px;
                }
            }
        }
        .edit-item-action {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 0.25rem;
            
            .action-content {
                // width: 1rem;
                // height: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                // border-radius: 0.25rem !important;
            }

            .action-icon {
                font-size: 0.75rem;
                color: var(--primary-color);

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }


    .info-row{
        display: flex;
        justify-content: space-between;
        .info-item{
            width: 50%;
            .value {
                &.performance {
                    color: var(--primary-color);
                }
                &.delivery-forecast {
                    cursor: pointer;
                }
                svg {
                    padding: 0px 5px;
                }
            }
        }
    }
}
.wrap-row {
    flex-wrap: wrap;
}