.deliverer-doc-item{
    margin-bottom: 20px;
    @media (min-width: 1200px){
        display: flex;
    }
    .label{
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #aaa;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 10px;
    }
    .doc-image{
        @media (min-width: 1200px){
            width: 150px;
            height: 150px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .buttons{
        display: flex;
        margin-top: 20px;
        @media (min-width: 1200px){
            display: block;
            margin-left: 20px;
            padding-top: 15px;
            margin-top: 0;
        }
        .button-item{
            margin-right: 20px;
            color: var(--light-primary-color);
            margin-bottom: 20px;
            font-size: 24px;
            cursor: pointer;
            user-select: none;
            @media (min-width: 1200px){
                font-size: 20px;
            }
        }
    }
}
