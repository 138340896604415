.radio-group-container {
    display: flex;
    margin-bottom: 12px;
    font-size: 14px;
    user-select: none;
    margin-right: 20px;
    input{
        display: none;
    }
    .outside-circle{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        .inner-circle{
            width: 6px;
            height: 6px;
            background-color: var(--primary-color);
            border-radius: 50%;
        }
    }
    .radio-label {
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
}


