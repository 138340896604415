.deliverer-info-page{
    .deliverer-info-page-content{
        padding: 20px;
        background-color: #f5f5f5;
        @media (min-width: 1200px){
            min-height: 82vh;
            padding-top: 50px;
            display: flex;
            justify-content: space-between;
            padding-right: 115px;
            align-items: flex-start;
        }
        @media (min-width: 1280px){
            padding-right: 20px;
        }

        .box{
            padding: 25px;
            background-color: #fff;
            border-radius: 8px;
            margin-bottom: 20px;
            &.left-side{
                @media (min-width: 1200px){
                    width: 22%;
                    margin-right: 10px;
                }
                @media (min-width: 1600px){
                    width: 23%;
                }
            }
            &.right-side{
                @media (min-width: 1200px){
                    width: 70%;
                }
            }

            .top-row{
                display: flex;
                align-items: center;
                width: 100%;
                padding-top: 10px;
                margin-bottom: 30px;
                .user-image{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 30px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }

                .user-name{
                    max-width: 60%;
                    flex: 1;
                    font-size: 14px;
                    font-weight: bold;
                    @media (min-width: 1200px){
                        font-size: 24px;
                    }
                }
            }

            .info-block{
                margin-bottom: 50px;
            }

            .info-section{
                border-bottom: 1px solid #ddd;
                padding-bottom: 20px;
                margin-bottom: 20px;
                &:last-child{
                    border: 0;
                }
                .title{
                    font-weight: bold;
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                .flex-container{
                    @media (min-width: 1200px){
                        display: flex;
                    }
                    .column{
                        margin-right: 20px;
                        @media (min-width: 1200px){
                            width: 250px;
                        }
                    }
                }
            }

            .buttons-row{
                margin-bottom: 20px;
                @media (min-width: 1200px){
                    display: flex;
                }
                .button-item{
                    border: 1px solid var(--primary-color);
                    text-align: center;
                    padding: 10px 20px;
                    border-radius: 4px;
                    background-color: var(--primary-color);
                    color: white;
                    transition: 0.4s;
                    margin-bottom: 20px;
                    cursor: pointer;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.outline{
                        background-color: #fff;
                        color: var(--primary-color);
                    }
                    &:hover{
                        opacity: 0.7;
                    }
                    @media (min-width: 1200px){
                        width: 45%;
                        margin-right: 20px;
                        margin-bottom: 10px;
                    }
                }

            }
            .remove{
                cursor: pointer;
                text-align: center;
                display: block;
                color: var(--primary-color);
                text-decoration: none;
                font-size: 12px;
                transition: 0.4s;
                &:hover{
                    opacity: 0.7;
                }
            }

            .reject-deliverer{
                textarea{
                    resize: none;
                    width: 98.5%;
                    height: 150px;
                    margin-bottom: 20px;
                }
            }

            .error{
                color: var(--red);
                margin-bottom: 5px;
            }
        }
    }
}
