.search-result-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    &:hover{
        background-color: #e8e8e8;
    }
    .order-info{
        text-transform: uppercase;
        font-size: 12px;
        color: #ababab;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .restaurant-name{
        font-weight: bold;
        font-size: 18px;
    }
}