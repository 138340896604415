.rates-listing-container {
    width: 100%;
    height: 100%;
    min-height: 410px;
}

.store-select-input-container{
    padding: 40px;
    padding-top: 0px !important;
    width: 100%;
    @media (min-width: 1200px){
        width: 350px;
    }
}

.creation-select-input-container{
    padding-top: 0px !important;
    width: 100%;
    @media (min-width: 1200px){
        width: 350px;
    }
}