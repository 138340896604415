.table-head-container{ 
    width: 100%;
    padding: 20px;
    border-bottom: 2px solid #e8e8e8;
    height: 30px;
    padding-left: 50px;
    display: none;
    @media (min-width: 1200px){
        display: flex;
        align-items: center;
    }
    .table-head-item{
        font-size: 12px;
       
        &.id{
            width: 5%;
        }
        &.store{
            width: 10%;
        }
        &.channel{
            width: 10%;
        }
        &.client{
            width: 30%;
            padding-left: 2%;
            padding-right: 2%;
        }
        &.value{
            width: 15%;
        }
        &.button-single{
            width: 10%;
            color: #ababab;
        }
        &.button-row{
            width: 35%;
        }
    }
}
