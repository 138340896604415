.item-input-form{
    margin-top: 24px;
    margin-bottom: 10px;
    user-select: none;
    .top-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .remove-button-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            color: #CF1D1C;
            margin-right: -10px;
            cursor: pointer;
            svg{
                margin-left: 10px;
            }
        }
    }

    .icon {
        position: absolute;
        right: 0px;
        top: 30px;
        z-index: 1;
        font-size: 22px;
    }

    label{
        font-size: 14px;
        font-weight: 500;
        width: auto;
        color: black;
        letter-spacing: 1px;
        margin-bottom: 5px;

        &.faded{
            color: grey;
        }
    }
    input, select, textarea{
        width: 100%;
        border: 0;
        border-radius: 4px;
        border: 1px solid var(--light-gray-color);
        height: 35px;
        font-size: 14px;
        margin-top: 5px;
        padding: 0;
        padding-left: 10px;
        font-family: unset;
        &.faded {
            border: 1px solid grey;
            background-color: #d6d6d6 !important;
        }
    }
    select {
        margin-top: 8px !important;
        height: 37px !important;
    }
    textarea{
        height: 80px;
        padding-top: 10px
    }
    .msg-erro{
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        margin-left: 3px;
        margin-top: 2px;
        svg {
            color: var(--secondary-color);
        }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        // color: white;
        opacity: 1; /* Firefox */
    }
    .error-container{
        width: 100% !important;
    }
}
