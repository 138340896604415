.express-rates-table-row-item{
    width: 100%;
    padding: 20px;
    padding-left: 50px;
    background-color: #fff;
    &:nth-child(odd){
        background: #f0f0f0;
    }
    @media (min-width: 1200px){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .rates-table-row-content{
        font-size: 14px;
        margin-bottom: 10px;
        width: 100%;

        &.id{
            width: 5%;
        }
        &.label{
            width: 15%;
            font-weight: 500;
        }
        &.charge-areas{
            width: 20%;
            display: flex;
            flex-direction: column;
        }
        &.delivery{
            width: 5%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .icon-value {
                display: flex;
                flex-direction: row;

                .icon {
                    margin-top: 1px;
                    margin-right: 5px;
                }
            }
        }
        &.status{
            width: 5%;
        }
        &.button-row{
            width: 25%;
            display: flex;
            .button-item{
                user-select: none;
                margin-right: 20px;
                background-color: var(--primary-color);
                padding: 10px 20px;
                border-radius: 4px;
                color: white;
                font-weight: bold;
                border: 1px solid var(--primary-color);
                cursor: pointer;
                &.outline{
                    background-color: transparent;
                    color: var(--primary-color);
                }
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
}
