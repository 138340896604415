.top-tags-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .queue-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
}

.see-queue{
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    text-decoration: underline;
    color:var(--primary-color);
   
    
}


.top-tag-item{
    display: flex;
    align-items: center;
    width: 120px;
    user-select: none;
    cursor: pointer;
    transition: 0.4s;
    overflow: hidden;
    &:hover{
        color: var(--primary-color);
    }
    
    @media (min-width: 600px){
        width: 160px;
        margin-left: 0;
    }
   
    .icone{
        display: none;
        font-size: 18px;
        max-width: 18px;
        @media (min-width: 600px){
            display: block;
        }
        svg{
            color: var(--primary-color);
        }
    }
    .value{
        font-size: 24px;
        color: var(--dark-primary-color);
        margin-right: 10px;
        font-weight: bold;
        @media (min-width: 600px){
            margin-left: 10px;
        }
    }
    .label{
        font-size: 12px;
        line-height: 1.2;
        font-weight: bold;
        width: 70px;
    }
}



.desktop-only{
    display: none;
    @media (min-width: 600px){
        display: block
    }
}