.transaction-list-page {
    .resume-container {
        padding-top: 60px;
        margin: 0 50px;

        .resume-blocks {
            display: flex;
            flex-direction: row;
            .resume-block {
                &:first-child {
                    margin-right: 60px;
                }
                .resume-item {
                    padding: 20px 0;
                }
            }
        }
    }

    .row-title-container {
        .filters-input-stack {
            .item-input-form {
                margin: 0;
                margin-right: 20px;
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
