.manage-deliverers-card-item{
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    margin-bottom: 20px;
    @media (min-width: 1200px){
        width: 24%;
        margin-right: 20px;
    }
    .top-row{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .icon{
            color: var(--light-primary-color);
            margin-right: 20px;
            font-size: 32px;
        }
        .number{
            font-weight: bold;
            font-size: 32px;
        }
    }

    .title{
        font-size: 18px;
        margin-bottom: 20px;
    }

    .button{
        background-color: var(--primary-color);
        color: white;
        padding: 10px 20px;
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 2px;
        border-radius: 4px;
        display: inline-flex;
        border: 1px solid var(--primary-color);
        transition: 0.4s;
        &:hover{
            background-color: #fff;
            color: var(--primary-color);
        }
    }
}
