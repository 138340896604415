.showcases-listing {
    display: flex;
    flex-direction: column;
    flex: 1;
    .showcase-item-header {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 1.25rem;
        padding: 1.25rem;

        .content {
            display: flex;
            flex-direction: row;
            align-items: center;

            gap: 0.75rem;

            //svg {
            //    padding-right: 45px;
            //}

            .title {
                //padding: 30px 20px 20px;
                font-size: 18px;
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
        }
    }
    .button-link-container {
        margin: 0;
        margin-left: 10px;
        .button-link {
            margin: 0;
            margin-top: 5px;
        }
    }
}
