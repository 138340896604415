.flex-table-draggable{
    .table-head-container{
        width: 100%;
        padding: 20px;
        border-bottom: 2px solid #e8e8e8;
        height: 30px;
        padding-left: 50px;
        display: none;
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .table-head-item{
            font-size: 12px;

            &.button-row{
                width: 30%;
            }
        }
    }

    .draggable-item-section {
        display: flex;
        flex-direction: column;
        width:  auto;
        background-color: #fff;
        &:nth-child(odd){
            background: #f0f0f0;
        }

        .accordion-content {
            padding-left: 50px;
            overflow: auto;
            transition: max-height 0.6s ease;
        }
    }

    .flex-table-row-item{
        padding: 20px 0 0;
        padding-left: 50px;
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        width: 100%;
        .flex-table-row-content{
            font-size: 14px;
            margin-bottom: 10px;
            width: 100%;

            &.button-row{
                width: 30%;
                display: flex;
                .button-item{
                    user-select: none;
                    margin-right: 20px;
                    background-color: var(--primary-color);
                    padding: 10px 20px;
                    border-radius: 4px;
                    color: white;
                    font-weight: bold;
                    border: 1px solid var(--primary-color);
                    cursor: pointer;
                    &.outline{
                        background-color: transparent;
                        color: var(--primary-color);
                    }
                    &:hover{
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

