.route-creation-page {

    .content-container {
        @media (min-width: 1200px) {
            height: 93vh !important;
            flex-direction: row;
        }

        @media (min-width: 1900px) {
            height: 87.5vh
        }
    }

    .orders-card-listing {
        padding: 10px;
        background-color: #f5f5f5;
        position: relative;
        width: 100%;

        &.padding-top {
            padding-top: 30px;
        }

        @media (min-width: 1200px) {
            height: 90vh;
            padding-left: 25px;
            overflow: auto;
        }

        .container-fix {
            margin-top: 30px;
        }

        .section-title {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .icon {
                margin-right: 10px;

                svg {
                    color: var(--light-primary-color);
                }
            }
        }

        .route-creation-section {
            border-bottom: 1px solid var(--light-gray-color);
            padding-bottom: 15px;
            margin-bottom: 30px;

            .text {
                margin-bottom: 15px;
            }

            .route-detail-steps-container {
                margin-bottom: 0;

                .text {
                    margin-left: 40px;
                }
            }
        }

        &.loading-column {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
        }

        .column-title {
            padding: 15px;

            @media (min-width: 1200px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .title-flex {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 10px;

                    svg {
                        color: var(--light-primary-color);
                    }
                }
            }
        }

        .filters {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
           

            .item-input {
                margin: 0px 5px;
                flex: 1;

                &:first-child {
                    flex: 2;
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                select {
                    flex: 1;
                    border: 1px solid var(--light-gray-color) !important;
                }

               
            }

        }

        .container-search {
            display: flex;

            .container-input {
                flex: 2;
                margin-right: 10px;
            }

            .container-districts {
                margin-top: 10px;
                flex: 1;
            }
        }

        .list-loader {
            width: 100%;
            height: 100%;
            position: relative;
        }
    }

    .left-column {
        box-sizing: border-box;

        @media (min-width: 1200px) {
            display: flex;
            flex-direction: row;
            width: 50%;
        }
    }

    .right-column {
        @media (min-width: 1200px) {
            width: 50%;
        }
    }
}