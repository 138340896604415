.operation-listing-scheduled-container{
    height: 79vh;
    .empty-message-container{
        margin-top: 50;
        display: flex;
        justify-content: center
    }

    .operation-row-container{
        overflow-y: auto;
        .group-title-container{
            background-color: white;
            padding: 20px;
            font-weight: bold;
            svg {
                color: var(--light-primary-color);
                margin-right: 10px;
            }
        }

        .row-title-container{
            background-color: #F5F5F5;
            padding: 25px;
            @media (min-width: 1200px){
                display: flex;
                justify-content: space-between;
                align-items: center;

            }

            .title-flex{
                display: flex;
                align-items: center;
                .icon{
                    margin-right: 10px;
                    svg{
                        color: var(--light-primary-color);
                    }
                }
            }
        }
        .orders-listing{
            height: 80%;
            overflow-y: auto;
            background-color: #f5f5f5;
            &:nth-child(2){
                background-color: #e8e8e8;
            }
        }
        .sort-button{
            width: 250px;
            margin-top: 20px;
            @media (min-width: 1200px){
                margin-top: 0;
            }
        }
    }

    .order-row{
        padding: 15px;
        background-color: #FAFAFA;
        align-items: center;
        border-left: 10px solid;
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;
        }
        &:nth-child(odd){
            background-color: #f0f0f0;
        }
        .order-info{
            width: 100%;
            margin-bottom: 10px;
            @media (min-width: 1200px){
                width: 34%;
                margin-bottom: 0;
            }
        }
        .client-info{
            width: 100%;
            margin-bottom: 20px;
            @media (min-width: 1200px){
                width: 34%;
                margin-bottom: 0;
            }
        }
        .time-info{
            width: 20%;
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            @media (min-width: 1200px){
                width: 25%;
            }
            &.buttons{
                margin-top: 30px;
                width: 7%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                @media (min-width: 1200px){
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 0;
                }
            }
            .timers{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .small-text{
            color: #aaa;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 5px;
            &.delivery-forecast{
                font-size: 11px;
                color: #666;
            }
        }

        .black-text{
            font-weight: bold;
            color: #000;
            font-size: 14px;
            margin-bottom: 5px;
            &.address{
                font-size: 12px;
                font-weight: normal;
            }
            &.info{
                font-size: 22px;
                cursor: pointer;
                svg{
                    color: var(--light-primary-color);
                }
            }
            &.name{
                font-size: 14px;
            }

        }

    }
}
