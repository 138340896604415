
.route-confirmation-modal{
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    z-index: 10;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 150px;
    @media (min-width: 1200px){
        align-items: center;
        padding-bottom: 0;
    }
    .modal-content{
        background-color: #fff;
        border-radius: 8px;
        min-height: 140px;
        max-height: 400px;
        max-width: 350px;
        padding: 20px;
        overflow: hidden;
        position: relative;
        z-index: 1000000000000000000000;
        @media (min-width: 1200px){
            margin-left: -80px;
            max-width: 400px;
        }

        .title-row{
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 20px;
        }

        .button-row{
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            .button-item{
                user-select: none;
                width: 40%;
                text-align: center;
                background-color: var(--primary-color);
                padding: 10px;
                border-radius: 4px;
                border: 1px solid var(--primary-color);
                color: white;
                font-weight: bold;
                font-size: 12px;
                cursor: pointer;
                &.outline{
                    background-color: #fff;
                    color: var(--primary-color);
                }

            }
        }
    }
}