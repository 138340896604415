.modal-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
    opacity: 1;
    visibility: visible;
    .modal-loading-box {
        transform: translate3d(0, 0, 0);
    }

}

.modal-loading-bg {
    background-color: #00000020;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 31;

}

.modal-loading-box {
    background-color: #ffffff;
    position: relative;
    z-index: 32;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 8px #00000020;
    transform: translate3d(0, -40, 0);
    transition: .4s;

}
