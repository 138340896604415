.load-more-container{
    width: 100%;
    text-align: center;
    padding: 30px 0;

    .load-button{
        min-width: 100px;
        border: 2px solid var(--primary-color);
        color: var(--primary-color);
        padding: 5px 20px;
        border-radius: 20px;
        display: inline-flex;
        justify-content: center;
        align-self: center;
        transition: 0.4s;
        cursor: pointer;
    }
}
