.notification-container{
    background-color: rgba(black, 0.9);
    width: 250px;
    height: 70px;
    position: fixed;
    bottom: -400px;
    right: 10px;
    border-radius: 8px;
    overflow: hidden;
    z-index: 50;
    padding: 10px;
    transition: 0.6s;
    &.visible{
        bottom: 100px;
    }
    @media (min-width: 1200px){
        width: 350px;
        height: 70px;
        position: fixed;
        bottom: -400px;
        right: 10px;
        padding-top: 2px;
    }
    .content-container{
        position: relative;
        width: 100%;
        height: 100%;
        .close-button{
            position: absolute;
            right: 0;
            top: 0;
            color: white;
            cursor: pointer;
        }

        .main-content{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .icon-container{
                font-size: 35px;
                padding: 0 10px 0 15px;
                svg {
                    color: var(--secondary-color);
                    size: 10px;
                }
            }

            .text{
                margin-left: 10px;
                &:hover{
                    .value{
                        text-decoration: underline;
                    }
                }
                .title{
                    color: white;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .value{
                    font-size: 12px;
                    color: #B1B1B1;
                    cursor: pointer;
                }
            }
        }
    }
}