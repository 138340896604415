.datetime-inputs-container{
    width: 97%;

    .datetime-inputs{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .input-item {
            width: 48%;
        }
    }
}