
.select-deliverer-modal{
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    z-index: 10;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 150px;
    @media (min-width: 1200px){
        align-items: center;
        padding-bottom: 0;
    }
    .deliverers-queue-container{
        background-color: #fff;
        border-radius: 8px;
        width: 300px;
        padding: 20px;
        height: 400px;
        overflow: hidden;
        position: relative;
        z-index: 1000000000000000000000;
        @media (min-width: 1200px){
            margin-left: -80px;
            width: 400px;
        }

        .title-row{
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .close{
                color: var(--light-primary-color);
                font-weight: 300;
                font-size: 22px;
                cursor: pointer;
            }
        }

        .deliverer-listing{
            width: 100%;
            height: 275px;
            overflow: auto;
        }
    }
}
