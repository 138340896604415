.mapboxgl-popup-content {
    cursor: default !important;
    background: none !important;
    box-shadow: none !important;
    padding: 0px !important;
}

.route-order-pin {
    width: 27px;
    height: 27px;
    border: 2px solid white;
    border-radius: 50% 50% 0 50%;
    background: var(--light-primary-color);
    position: relative;
    bottom: 10px;
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
        font-size: 20px;
        color: white;
        font-weight: bold;
        transform: rotate(-45deg);
    }
}

.popup-order-container {
    padding: 10px;
    overflow: hidden;
    height: auto;
    position: relative;
    top: 10px;

    &.order-item {
        top: 5px;
        width: 200px;
    }

    &.ready-deliver {
        height: 120px;
        width: 210px;
    }

    &.route-item {
        top: 10px;
        width: 210px;
    }

    .popup-order-content{
        border-top: 10px solid var(--secondary-color);
        width: 80%;
        background-color: #FFF;
        padding: 10px;
        position: relative;
        border-radius: 5px;

        .close-button{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 14px;
            color: var(--primary-color);
            font-weight: 300;
            cursor: pointer;
        }
    }

    .popup-order-title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    .popup-order-item {
        margin-bottom: 5px;
        width: 100%;
        display: flex;

        .icon {
            margin-right: 5px;
            width: 10px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                color: var(--primary-color);
            }
        }
    }

    .popup-order-item-text {
        margin-left: 5px;
        cursor: pointer;

        &.store {
            font-weight: 600;
        }
    }

    .arrow-order-container{
        position: absolute;
        bottom: -20px;

        &.order-item {
            left: 75px;
        }

        &.route-item {
            left: 70px;
        }

        color: #FFF;
        font-size: 30px;
        z-index: 99999;
    }
}

.popup-area-container {
    padding: 10px;
    overflow: hidden;
    min-width: 100px;
    height: 40px;
    position: relative;

    .popup-area-content{
        border-top: 10px solid #F5A623;
        width: 90%;
        background-color: #FFF;
        padding: 10px;
        position: relative;
        border-radius: 5px;
    }

    .popup-area-title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    .popup-area-item-text {
        margin-left: 10px;
    }
}

.map-controls-container{
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    top: 80px;
    right: 10px;
    .checkbox-input {
        margin: 0;
    }
}

.info-map-message-container{
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    bottom: 200px;
    left: 200px;
    right: 200px;
    text-align: center;

    .info-map-message{
        font-size: 12px;
    }
}

.popup-biker-container {
    padding: 10px;
    overflow: hidden;
    height: auto;
    width: 250px;
    position: relative;
    top: 10px;

    .popup-biker-content{
        border-top: 10px solid var(--primary-color);
        width: 80%;
        background-color: #FFF;
        padding: 10px;
        position: relative;
        border-radius: 5px;

        .close-button{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 14px;
            color: var(--primary-color);
            font-weight: 300;
            cursor: pointer;
        }
    }

    .popup-biker-title {
        font-size: 12px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        padding-right: 5px;
        svg {
            font-size: 20px;
        }
    }

    .popup-biker-item {
        margin-bottom: 5px;
        width: 100%;
        display: flex;

        .icon {
            margin-right: 5px;
            width: 10px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                color: var(--primary-color);
            }
        }
    }

    .popup-biker-item-text {
        margin-left: 5px;
        cursor: pointer;
    }
}
