.performance-page-top-row-container {
    text-align: left;
    padding: 25px;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 100px;
    position: relative;
    z-index: 10;
    @media (min-width: 1200px){
       padding: 25px;
       height: 100px;
       box-shadow: -2px 0px 20px 0px rgba(0,0,0,0.4);
    }
    .row-item {
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
        }
    }

    .inputs-row {
        @media (min-width: 1200px){
            display: flex;
            align-items: flex-end;
        }

        .filter-select{
            margin-right: 10px;
            min-width: 120px;
        }

        .item-input-form {
            margin: 0;
            margin-right: 20px;
            input {
                margin: 0;
                height: 29px;
                min-width: 120px;
            }
        }

        .filter-button{
            background-color: var(--primary-color);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            padding: 7px 20px;
            border-radius: 4px;
            transition: 0.4s;
            margin-top: 20px;
            border-radius: 20px;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }

            &.outline{
                background-color: white;
                color: var(--primary-color);
                border: 1px solid var(--primary-color);
            }
            @media (min-width: 1200px) {
                margin-left: 10px;
                margin-top: 0;
            }
        }

        .date-filters {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            flex-shrink: 0;
            @media (min-width: 1200px){
                border-left: 1px solid var(--primary-color);
                margin-left: 20px;
                padding: 0 10px;
            }
        }
    }

    .left-column {
        box-sizing: border-box;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        @media (min-width: 1200px){
            margin-bottom: 0;
        }
    }

    .right-column{
        @media (min-width: 1200px){
            display: flex;
            align-items: flex-end;
        }
    }

    .top-row-title{
        font-weight: bold;
        font-size: 22px;
    }
}
