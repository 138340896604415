.search-deliverer-modal{
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    z-index: 1;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 150px;
    @media (min-width: 1200px){
        align-items: center;
        padding-bottom: 0;
    }
    .deliverers-listing-container{
        background-color: #fff;
        border-radius: 8px;
        width: 300px;
        padding: 20px;
        height: 500px;
        overflow: hidden;
        position: relative;
        z-index: 1000000000000000000000;
        @media (min-width: 1200px){
            margin-left: -80px;
            width: 400px;
        }
    }
}

.deliverers-listing-container{
    border-radius: 8px;
    width: 300px;
    overflow: hidden;
    position: relative;
    @media (min-width: 1200px){
        width: auto;
    }

    .title-row{
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .text{
            font-weight: normal;
            font-size: initial;
        }
        .close{
            color: var(--light-primary-color);
            font-weight: 300;
            font-size: 22px;
            cursor: pointer;
        }
    }

    .deliverer-listing{
        width: 100%;
        min-height: 250px;
        overflow: auto;
        .search-input-container {
            width: 95%;
            margin-bottom: 30px;
            text-align: center;
            .item-input-form{
                margin-bottom: 30px;
            }
        }
    }
}

