:root {
    --primary-color: #FF860A;
    --dark-primary-color: #C44300;
    --light-primary-color: #FF9933;
    --lighter-primary-color: #F7E8D7;
    --secondary-color: #FFC612;
    --dark-gray-color:  #333333;
    --gray-color: #666666;
    --light-gray-color: #999999;

    --green: #7ED321;
    --yellow: #F5A623;
    --red: #D0021B;
}
