.deliverer-item{
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    .circle-outside{
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 2px solid var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        .circle-inside{
            width: 12px;
            height: 12px;
            background-color: #fff;
            border-radius: 10px;
            &.active{
                background-color: var(--primary-color);
            }
        }
    }
    cursor: pointer;
    label{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    input{
        margin-right: 20px;
    }

    .deliverer-photo{
        width: 50px;
        height: 50px;
        border-radius: 40px;
        overflow: hidden;
        margin-right: 20px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .deliverer-info{
        user-select: none;
        width: 50%;
        .deliverer-name{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        .vehicle{
            font-size: 12px;
            color: #ababab;
            font-weight: bold;
        }
        .device {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            font-size: 10px;
            color: #ababab;
            text-transform: uppercase;
            font-weight: bold;
            .device-info-item {
                margin-right: 5px;
                &.strike {
                    color: var(--primary-color);
                }
            }
        }
    }
    .has-route-container{
        background-color: var(--red);
        border-radius: 50px;
        padding: 5px 10px;
        font-size: 12px;
        color: white;
    }
}
